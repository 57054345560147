import PostMessageType from '../../../constants/postMessageTypes';
import { to } from '../../../utils';
import { apiErrorCodeMap } from '../../../utils/errorMap';
import { getAuthDataFromTransaction, throwError } from '../transactionHelpers';

async function handleLoginRequired({ message, transaction, broker }) {
  if (message.data.type === PostMessageType.LOGIN_REQUIRED) {
    const [loginError] = await to(
      this.login({ broker, intent: transaction.intent }),
    );
    if (loginError) {
      await throwError({
        markTransaction: true,
        errorObj: apiErrorCodeMap[1011],
        transactionId: transaction.transactionId,
        data: getAuthDataFromTransaction(transaction, broker),
      });
    }
    return { restartTransaction: true };
  }
  return { restartTransaction: false };
}

export default handleLoginRequired;
