import { store } from '../../../utils/userStore';
import { LEPRECHAUN_SUFFIX } from '../../../constants';
import errorMap from '../../../utils/errorMap';
import WindowManager from '../../../utils/windowManager';
import transactionStatus, {
  completionStatus,
} from '../../../constants/transactionStatus';
import PostMessageType from '../../../constants/postMessageTypes';
import { to, fetchBrokerConfig, getTransactionOpenMode } from '../../../utils';
import {
  throwError,
  addTransactionListeners,
  fetchTransactionStatus,
  pollTransactionStatus,
  handleErrorResponse,
  handleUserCancelledErrors,
} from '../transactionHelpers';
import { IntentHandler } from '../intentHandlerTypes';

const intentHandler: IntentHandler = {
  // handle connect UI + open broker platform related things
  async handleBrokerPlatform({ transaction, broker }) {
    const brokerConfig = fetchBrokerConfig(broker);
    const transactionOpenMode = getTransactionOpenMode(
      brokerConfig,
      store.thirdPartyCookieSupported,
    );
    const transactionURL = brokerConfig.getIntentURL({
      transactionId: transaction.transactionId,
      broker,
      openMode: transactionOpenMode,
      gateway: store.gateway,
      intent: transaction.intent,
    });
    await WindowManager.closeSecondary();
    const secondaryURL = `pre-connect/?broker=${broker.replace(
      LEPRECHAUN_SUFFIX,
      '',
    )}`;
    const completionPromise = addTransactionListeners({
      broker,
      transactionId: transaction.transactionId,
    });
    await WindowManager.openIndirect({
      primaryURL: transactionURL,
      primaryWindowType: transactionOpenMode,
      secondaryStatus: secondaryURL,
      broker,
      intent: transaction.intent,
    });
    return {
      shouldReturn: false,
      completionPromise,
    };
  },

  async handleCompletion({ transactionId, message }) {
    const [fetchStatusError, statusResponse] = await to(
      fetchTransactionStatus(transactionId),
    );

    if (fetchStatusError) {
      await throwError({
        markTransaction: false,
        error: errorMap.API_ERROR,
      });
    }

    let { transaction } = statusResponse;
    const userCancelled = [
      PostMessageType.USER_CANCELLED,
      PostMessageType.CLOSED_WINDOW,
    ].includes(message.data.type);

    if (transaction.status === transactionStatus.PROCESSING) {
      await WindowManager.closePrimary();
      transaction = await pollTransactionStatus(transaction.transactionId);
    }

    if (
      [transactionStatus.PROCESSING, transactionStatus.COMPLETED].includes(
        transaction.status,
      )
    ) {
      await WindowManager.closeAll();
    } else if (userCancelled) {
      await handleUserCancelledErrors(transaction);
    }

    if (message.data.status === completionStatus.ERRORED) {
      return handleErrorResponse(message, transaction);
    }

    return {
      smallcaseAuthToken: transaction.success.smallcaseAuthToken,
      ...(!!transaction.success.data && transaction.success.data),
      ...(typeof transaction.success.signup === 'boolean' && {
        signup: transaction.success.signup,
      }),
      transactionId: transaction.transactionId,
      ...(transaction.success.broker && { broker: transaction.success.broker }),
    };
  },
};

export default intentHandler;
