import NonTransactionalIntent from '../../constants/nonTransactionalIntents';
import { isSdkReady } from '../../utils/sdkReadiness';
import getBroker from '../transaction/utils/getBroker';
import handleLoginRequired from '../transaction/utils/handleLoginRequired';
import brokerLogoutIntentHandler from '../transaction/intentHandler/brokerLogoutIntentHandler';

async function brokerLogout({ brokers = [], leprechaun = false } = {}) {
  await isSdkReady();
  // Transaction Handler
  const th = brokerLogoutIntentHandler;

  const broker = await getBroker.call(this, {
    brokers,
    leprechaun,
    intent: NonTransactionalIntent.BROKER_LOGOUT,
    skipLogin: true,
  });

  const { completionPromise } = await th.handleBrokerPlatform({ broker });

  const message = await completionPromise;
  const transaction = { intent: NonTransactionalIntent.BROKER_LOGOUT };

  const { restartTransaction } = await handleLoginRequired.call(this, {
    message,
    broker,
    transaction,
  });

  if (restartTransaction) {
    return brokerLogout.call(this, {
      brokers,
      leprechaun,
    });
  }

  return th.handleCompletion({ message, broker });
}

export default brokerLogout;
