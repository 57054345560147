import { wait } from '.';

let oldOnPopState: typeof onpopstate = null;
let backDisabled: boolean = false;
let functionId: string;
/**
 * Prevent user from going back when gateway ui is open.\
 * How this works:
 * 1. when iframe opens up, a new history entry is created why pushState\
 * pushState changes the url but doesnt make an http request
 * 2. If the user were to do browser-back,
 * popstate event is triggered and another pushState executes
 *
 * caveat: pushstate replaces anything on top of the current history entry,
 * ie. erases forward history
 * https://stackoverflow.com/a/34337617/10500599
 */
export function disableBackButton(id: string) {
  if (!backDisabled) {
    backDisabled = true;
    functionId = id;
    // backup any popstate handler created by customer
    oldOnPopState = window.onpopstate;
    
    window.history.pushState(null, document.title, window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
    };
  }
}

/**
 * Restore back button functionality.\
 * A browser back is triggered from code so that an actual browser back would work correctly
 */
export function enableBackButton(id: string) {
  if (
    backDisabled &&
    functionId &&
    id === functionId
  ) {
    functionId = undefined;
    backDisabled = false;
    window.history.go(-1);
  }
}

export function listenOnPopState(func) {
  window.onpopstate = func;
}

/**
 * popstate listener is restored to original listener(should be null unless partner has overriden)
*/
export function restoreOnPopState() {
  window.onpopstate = oldOnPopState;
}

export function isBackDisabled(): boolean {
  return backDisabled;
}

export async function waitForBackEnable(functionIdentifier: string) {
  window.onpopstate = null;

  // listen for a popstate event (which happens with back enabling)
  const popstatePromise = new Promise((resolve) => {
    listenOnPopState(() => {
      resolve(true);
    });
  });

  // if child window added more history state, sdk window wont get popstate
  // we will wait upto 200ms to get event or we will just yield
  const popstateTimeoutPromise = wait(200);
  
  enableBackButton(functionIdentifier);

  await Promise.race([popstatePromise, popstateTimeoutPromise]);

  // restore partner's onpopstate
  restoreOnPopState();
}
