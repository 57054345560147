import init from '../methods/init';
import errorMap from '../utils/errorMap';
import login from '../methods/login';
import getSmallcases from '../methods/getSmallcases';
import getSmallcaseProfile from '../methods/getSmallcaseProfile';
import getStatus from '../methods/getStatus';
import getNews from '../methods/getNews';
import getHistorical from '../methods/getHistorical';
import getUserInvestmentDetails from '../methods/getUserInvestmentDetails';
import getSipStatus from '../methods/getSipStatus';
import getUserInvestments from '../methods/getUserInvestments';
import markArchived from '../methods/markArchived';
import signup from '../methods/signup';
import WindowManager from '../utils/windowManager';
import getExitedSmallcases from '../methods/getExitedSmallcases/getExitedSmallcases';
import { store } from '../utils/userStore';
import guardedCall from '../services/sentryService';
import showOrders from '../methods/showOrders';
import brokerLogout from '../methods/brokerLogout';
import openSmallplug from '../methods/openSmallplug';
import { ensureConnectFrameInjected } from '../utils/iframe';
import triggerTransaction from '../methods/transaction/triggerTransaction';
import triggerMfTransaction from '../methods/transaction/legacyTriggerMfTransaction';
import { updateSdkReadinessPromise } from '../utils/sdkReadiness';

type SDKConstructorParams = {
  gateway?: string;
  userData?: any;
  elementOrSelector?: string | HTMLElement;
  smallcaseAuthToken?: string;
  deviceType?: string;
  config?: any;
  initHandler?: (
    gatewayToken: string,
    displayName: string,
    status: string,
  ) => void;
};

/**
 * scDK class
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
class scDK {
  static _instance: scDK;

  iframeLoaded: Promise<void>;

  initPromise: Promise<boolean>;

  /**
   * Create object for smallcase DeceloperKit
   * Calls `init` method, and set's `CSRF` token
   * @param {string} gateway
   *
   */
  constructor({
    gateway,
    userData,
    elementOrSelector = 'body',
    smallcaseAuthToken,
    deviceType,
    config = {
      amo: false,
    },
    initHandler,
  }: SDKConstructorParams = {}) {
    if (!gateway || typeof gateway !== 'string') {
      throw errorMap.INVALID_GATEWAY;
    }

    if (scDK._instance !== undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        "scDK follows Singleton Pattern, thus doesn't allow creation of multiple instances and if the constructor is called multiple times, the first instance is returned each time.",
      );
      // eslint-disable-next-line no-constructor-return
      return scDK._instance;
    }

    scDK._instance = this;
    store.gateway = gateway;
    store.deviceType = deviceType;
    store.amo = !!config && !!config.amo;

    WindowManager.elementOrSelector = elementOrSelector;

    updateSdkReadinessPromise('iframeLoaded', ensureConnectFrameInjected());
    // Setup show hide listeners for iframe

    this.init({ userData, smallcaseAuthToken, initHandler });
  }
}

// use decalration merging to support adding method to prototype
// eslint-disable-next-line @typescript-eslint/naming-convention
interface scDK {
  init: typeof init;
  login: typeof login;
  getSmallcases: typeof getSmallcases;
  getSmallcaseProfile: typeof getSmallcaseProfile;
  getStatus: typeof getStatus;
  getNews: typeof getNews;
  getHistorical: typeof getHistorical;
  getUserInvestments: typeof getUserInvestments;
  getUserInvestmentDetails: typeof getUserInvestmentDetails;
  markArchived: typeof markArchived;
  triggerTransaction: typeof triggerTransaction;
  getExitedSmallcases: typeof getExitedSmallcases;
  signup: typeof signup;
  showOrders: typeof showOrders;
  brokerLogout: typeof brokerLogout;
  getSipStatus: typeof getSipStatus;
  openSmallplug: typeof openSmallplug;
  triggerMfTransaction: typeof triggerMfTransaction;
}

// Register methods here
scDK.prototype.init = guardedCall(init);
scDK.prototype.login = guardedCall(login);
scDK.prototype.getSmallcases = guardedCall(getSmallcases);
scDK.prototype.getSmallcaseProfile = guardedCall(getSmallcaseProfile);
scDK.prototype.getStatus = guardedCall(getStatus);
scDK.prototype.getNews = guardedCall(getNews);
scDK.prototype.getHistorical = guardedCall(getHistorical);
scDK.prototype.getUserInvestments = guardedCall(getUserInvestments);
scDK.prototype.getUserInvestmentDetails = guardedCall(getUserInvestmentDetails);
scDK.prototype.markArchived = guardedCall(markArchived);
scDK.prototype.triggerTransaction = guardedCall(triggerTransaction, true);
scDK.prototype.getExitedSmallcases = guardedCall(getExitedSmallcases);
scDK.prototype.signup = guardedCall(signup, true);
scDK.prototype.showOrders = guardedCall(showOrders);
scDK.prototype.brokerLogout = guardedCall(brokerLogout);
scDK.prototype.getSipStatus = guardedCall(getSipStatus);
scDK.prototype.openSmallplug = guardedCall(openSmallplug);
scDK.prototype.triggerMfTransaction = guardedCall(triggerMfTransaction);

export default scDK;
