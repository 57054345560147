import { getRiskLabel } from '../../utils';
import httpHandler, { RequestType } from '../../services/apiService';
import apiMap from '../../utils/apiMap';
import errorMap from '../../utils/errorMap';
import { SmallcaseProfileResponseType } from '../../types/apiResponses/getSmallcaseProfile';
import { SmallcaseProfileData as ScProfileData } from '../../types/smallcase';
import { isSdkReady } from '../../utils/sdkReadiness';

type SmallcaseProfileData = ScProfileData & {
  riskLabel: string;
  imageUrl: string;
};

/**
 *
 * @this {import('../../scdk').default}
 * @param {Object} args
 * @param {string} args.scid
 * @returns {Promise<Object>} smallcaseObject
 */
function getSmallcaseProfile({ scid }: { scid?: string } = {}) {
  if (!scid) {
    return Promise.reject(errorMap.INVALID_SCID);
  }
  return isSdkReady()
    .then(() =>
      httpHandler<SmallcaseProfileResponseType>(
        RequestType.GET,
        apiMap.SC_PROFILE,
        {
          scid,
        },
      ),
    )
    .then((res) => res.data)
    .then((smallcase: SmallcaseProfileData) => {
      smallcase.riskLabel = getRiskLabel(smallcase.stats.ratios.risk);
      smallcase.imageUrl = `https://assets.smallcase.com/images/smallcases/200/${smallcase.scid}.png`;
      return smallcase;
    });
}

export default getSmallcaseProfile;
