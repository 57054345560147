import errorMap from './errorMap';

type ReadinessGuards = 'iframeLoaded' | 'initSessionApi';

const sdkReadinessPromises: Record<ReadinessGuards, Promise<unknown> | null> = {
  iframeLoaded: null,
  initSessionApi: null,
};

/**
 * Updates the SDK readiness promise with the given key and value.
 * @param key - The key to identify the readiness promise.
 * @param value - The promise representing the readiness of the SDK.
 */
export const updateSdkReadinessPromise = (
  key: ReadinessGuards,
  value: Promise<unknown>,
) => {
  sdkReadinessPromises[key] = value;
};

/**
 * Checks if the SDK is ready by resolving all the readiness promises.
 * @returns A promise that resolves when all the readiness promises are resolved, or rejects with an API error.
 */
export const isSdkReady = () =>
  Promise.all(
    Object.values(sdkReadinessPromises).map(
      (promise) => promise || Promise.reject(errorMap.API_ERROR),
    ),
  );
