import { to } from '../utils';
import BaseLasStrategy from './BaseLasStrategy';
import lasRunner from './LasRunnerUtil';
import { triggerWithdrawPrincipal } from './api';
import ScLoanError from './utils/scLoanError';
import { UnityErrorResponse } from '../types/apiResponses/unityResponses';

class WithdrawStrategy extends BaseLasStrategy {
  constructor(interactionToken: string) {
    super(interactionToken);
    this.interactionParams = {
      ...this.interactionParams,
      connectFramePath: 'smallcase-loader',
    };
  }

  async runInteraction() {
    if (this.interactionDetails.openPlatform) {
      await super.runInteraction();
    } else {
      const [err] = await to<any, UnityErrorResponse>(
        triggerWithdrawPrincipal(
          this.interactionToken,
          `${this.interactionDetails.url}`,
        ),
      );
      if (err)
        throw new ScLoanError(
          err.response.data.message,
          err.response.data.code,
        );
    }
  }
}

type WithdrawPrincipalArgs = {
  interactionToken?: string;
};
async function withdrawPrincipal({
  interactionToken,
}: WithdrawPrincipalArgs = {}) {
  const intentStrategy = new WithdrawStrategy(interactionToken);
  return lasRunner(intentStrategy);
}

export default withdrawPrincipal;
