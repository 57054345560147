let oldActiveElement: HTMLElement = null;
/**
 * save reference to current ActiveElement
 */
export function backupActiveElement() {
  oldActiveElement = (document.activeElement as HTMLElement);
}

/**
 * restore the ActiveElement from before iframe was shown
 */
export function restoreActiveElement() {
  if (oldActiveElement) oldActiveElement.focus();
  oldActiveElement = null;
}
