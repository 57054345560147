/* eslint-disable max-classes-per-file */
class NetworkRequestFailedError extends Error {
  static message = 'NetworkRequestFailed';

  res?: any;

  constructor(responseObj) {
    super(NetworkRequestFailedError.message);
    this.res = responseObj.data;
  }
}

class BrokerNotConnectedError extends Error {
  static message = 'BrokerNotConnected';

  res?: any;

  constructor(responseObj) {
    super(BrokerNotConnectedError.message);
    this.res = responseObj.data;
  }
}

class GatewayError extends Error {
  code?: string;

  data?: any;

  constructor(message: string, code = null, data = {}) {
    super(message);
    this.name = 'GatewayError';
    this.code = code;
    this.data = data;
  }
}

export { NetworkRequestFailedError, BrokerNotConnectedError, GatewayError };
