import { LasPostMessageType, lasPostMessageTypes } from '../constants';

export type LasClientMessage = {
  type: LasPostMessageType;
  payload: {
    success: boolean;
    code: number;
    message: string;
  };
};

const terminalMessageTypes: LasPostMessageType[] = [
  lasPostMessageTypes.COMPLETE,
  lasPostMessageTypes.USER_CANCELLED,
];
export function listenForClientMessage(urlString: string) {
  const clientUrl = new URL(urlString);
  let removeListener = () => null;
  const listenerPromise: Promise<MessageEvent<LasClientMessage>> = new Promise(
    (resolve) => {
      const handler = (ev: MessageEvent<LasClientMessage>) => {
        if (ev.origin !== clientUrl.origin) return;
        if (terminalMessageTypes.includes(ev.data.type)) {
          removeListener();
          resolve(ev);
        }
      };
      removeListener = () => window.removeEventListener('message', handler);
      window.addEventListener('message', handler);
    },
  );

  return listenerPromise;
}

export function setupAskOriginResponse() {
  window.addEventListener('message', (event) => {
    if (event.data.type === lasPostMessageTypes.ASK_APP_ORIGIN)
      (event.source as Window).postMessage(
        { type: lasPostMessageTypes.GIVE_APP_ORIGIN },
        '*',
      );
  });
}
