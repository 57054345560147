import axios, { AxiosHeaderValue, AxiosResponse, Method } from 'axios';
import { store } from '../utils/userStore';

export const defaultUnityHeaders = {
  'Content-Type': 'application/json',
  'x-gateway-sdk-version': 'v3.0.0',
  'x-gateway-sdk-type': 'web',
};

function httpRequest() {
  const requestHandler = axios.create({
    baseURL: UNITY_API_URL,
    headers: defaultUnityHeaders,
  });
  return <T>(
    method: Method,
    endpoint: string,
    data?: any,
    customHeaders?: Record<string, AxiosHeaderValue>,
  ): Promise<AxiosResponse<T>> =>
    requestHandler(`client/${store.loanGateway}${endpoint}`, {
      method,
      withCredentials: true,
      headers: customHeaders,
      [method.toLowerCase() === 'get' ? 'params' : 'data']: data,
    });
}

const unityApiHandler = httpRequest();

export const craftInteractionHeader = (interactionToken: string) => ({
  'x-gateway-interaction': interactionToken,
});

export default unityApiHandler;
