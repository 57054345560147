import {
  listenMessageEvent,
  fetchBrokerConfig,
  getTransactionOpenMode,
} from '../../utils';
import errorMap from '../../utils/errorMap';
import WindowManager from '../../utils/windowManager';
import { GatewayLogin } from '../../constants';
import PostMessageType from '../../constants/postMessageTypes';
import { store } from '../../utils/userStore';
import NonTransactionalIntent from '../../constants/nonTransactionalIntents';
import TransactionIntent from '../../constants/transactionIntents';
import { isSdkReady } from '../../utils/sdkReadiness';

/**
 * `login` method will open a login window
 * where the user will login in their broker platform.
 *
 * @this {import('../../scdk').default}
 * @param {Object} args
 * @param {string?} args.broker
 * @returns {Promise<boolean>} Returns connectToken if login was successful.
 */

type LoginConfig = {
  broker?: string;
  intent: NonTransactionalIntent | TransactionIntent;
  skipLogin?: boolean;
};

async function login(
  { broker = store.broker, intent, skipLogin = false }: LoginConfig = {
    intent: undefined,
  },
) {
  await isSdkReady();
  // Get login window URL and open mode from broker config for connected broker
  const brokerConfig = fetchBrokerConfig(broker);
  if (Object.keys(brokerConfig).length === 0) {
    throw errorMap.API_ERROR;
  }
  if (brokerConfig.skipLogin || skipLogin) {
    return null;
  }
  const loginURL = brokerConfig.getIntentURL({
    action: GatewayLogin,
    gateway: store.gateway,
    gatewayName: store.displayName,
    openMode: brokerConfig.loginOpenMode,
    deviceType: store.deviceType,
    checkSession: true,
    broker,
  });
  const openMode = brokerConfig.loginOpenMode;

  // Define frame message handler callback
  const onMessagePromiseObj = listenMessageEvent(PostMessageType.GATEWAY_LOGIN);

  // Prepare frame message handler callback
  const onMessagePromise = onMessagePromiseObj.promise.then(() => true);

  // Prepare user cancellation Promise
  const onUserClose = listenMessageEvent(PostMessageType.CLOSED_WINDOW);
  const onUserCancel = listenMessageEvent(PostMessageType.USER_CANCELLED);

  const cleanupListeners = () => {
    onMessagePromiseObj.cancel();
    onUserClose.cancel();
    onUserCancel.cancel();
  };

  const onUserCancellationPromise = Promise.race([
    onUserClose.promise,
    onUserCancel.promise,
  ]).then(async () => {
    cleanupListeners();
    throw errorMap.USER_INIT_THEN_CANCEL;
  });

  // open frame now
  WindowManager.openIndirect({
    primaryURL: loginURL,
    primaryWindowType: openMode,
    secondaryStatus: `pre-connect/?broker=${broker}`,
    broker,
    intent,
  });

  // return promise
  return Promise.race([onMessagePromise, onUserCancellationPromise]).then(
    async (value) => {
      // Remove handlers
      cleanupListeners();
      const transactionOpenMode = getTransactionOpenMode(
        brokerConfig,
        store.thirdPartyCookieSupported,
      );
      const shouldClose = transactionOpenMode === 'iframe';
      // Remove frame
      if (shouldClose) {
        await WindowManager.closeAll();
      }
      return value;
    },
  );
}

export default login;
