import WindowManager, {
  ChangeTarget,
  WindowType,
} from '../../utils/windowManager';
import { listenMessageEvent } from '../../utils';
import PostMessageType from '../../constants/postMessageTypes';
import { store } from '../../utils/userStore';
import { isSdkReady } from '../../utils/sdkReadiness';

const getEncodedParams = (params: {
  [key: string]: string | number | boolean;
}) => {
  const paramStr = Object.keys(params).reduce((urlParams, param) => {
    if (params[param]) {
      return `${urlParams}${param}=${encodeURIComponent(params[param])}&`;
    }
    return urlParams;
  }, '');
  return paramStr.slice(0, -1);
};

async function signup({
  name,
  email = '',
  contact = '',
  pinCode,
}: {
  name?: string;
  email?: string;
  contact?: string;
  pinCode?: string;
} = {}) {
  await isSdkReady();

  await WindowManager.openIndirect({
    changeOnly: ChangeTarget.SECONDARY,
    secondaryStatus: 'smallcase-loader',
  });

  const validatedContact =
    typeof contact === 'string' || typeof contact === 'number' ? contact : '';
  const validatedEmail = typeof email === 'string' ? email : '';

  const primaryURL = `${SIGNUP_URL}?${getEncodedParams({
    name,
    email: validatedEmail,
    contact: validatedContact,
    pinCode,
    gateway: store && store.gateway,
    showCloseBtn: true,
  })}`;

  await WindowManager.openIndirect({
    primaryWindowType: WindowType.IFRAME,
    primaryURL,
  });

  await WindowManager.closeSecondary();

  const onUserSignupCloseListener = listenMessageEvent(
    PostMessageType.USER_SIGNUP_CLOSE,
  );

  const userAction = Promise.race([onUserSignupCloseListener.promise]);

  const cancelListeners = () => {
    onUserSignupCloseListener.cancel();
  };

  const eventMessage = await userAction;
  cancelListeners();

  const eventData = eventMessage.data;
  if (eventData.type === PostMessageType.USER_SIGNUP_CLOSE) {
    await WindowManager.closePrimary();
    return eventData.leadData;
  }
  return {
    leadId: null,
    status: null,
  };
}

export default signup;
