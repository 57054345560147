import httpHandler, { RequestType } from '../../services/apiService';
import { MarkArchivedResponse } from '../../types/apiResponses/markArchived';
import apiMap from '../../utils/apiMap';
import { store } from '../../utils/userStore';

/**
 *  Starts the session between smallcase API and gateway.
 * @this {import('../../scdk').default}
 * @param {Object} args
 * @param {string} args.iscid - **Required** Iscid - whose last batch to mark as archived
 *
 * @returns {Promise<boolean>} - TBD
 */
async function markArchived({
  iscid,
}: {
  iscid?: string;
} = {}) {
  const res = await httpHandler<MarkArchivedResponse>(
    RequestType.POST,
    apiMap.CANCEL_BATCH,
    {
      iscid,
    },
  );
  await this.init({ smallcaseAuthToken: store.smallcaseAuthToken });
  return {
    orderBatches: [res.data.batch?.lastBatch && [res.data.batch.lastBatch]],
    smallcaseDeleted: res.data.smallcaseDeleted,
  };
}

export default markArchived;
