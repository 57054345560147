import qs from 'query-string';
import { WindowType } from '../utils/windowManager';
import NonTransactionalIntent from './nonTransactionalIntents';
import TransactionIntent from './transactionIntents';

const pathMap = {
  seeOrders: '/orders',
  brokerLogout: '/brokerLogout',
  checkSession: '/checkSession',
  transaction: '/gatewaytransaction/',
};

const gatewaytransaction = 'gatewaytransaction';

type ParamsObj = {[key: string]: string|boolean};

const getEncodedPathParams = (params: ParamsObj, path: string):string => {
  const paramStr = qs.stringify({
    ...params,
  });

  return qs.stringify({
    params: paramStr,
    path,
  });
};


const formIntentUrl = (path: string, params: ParamsObj) => {
  const pathString = qs.stringify({
    action: gatewaytransaction,
    v: VERSION,
    ...params,
  });
  return `${path}?${pathString}`;
};

const formLoginIntentUrl = (path: string, params: ParamsObj) => {
  const updatedParams = {
    ...params,
    action: gatewaytransaction,
    v: VERSION,
    ...ENV === 'staging' && { staging: true },
  };
  return getEncodedPathParams(updatedParams, path);
};

// eslint-disable-next-line complexity
function getIntentURL(d: {
  intent: TransactionIntent | NonTransactionalIntent;
  broker: string;
  openMode: WindowType;
  gateway: string;
  gatewayName: string;
  checkSession: boolean;
  action: string;
  loginIntentURL: boolean;
  transactionId: string;
}): string {
  const {
    broker, openMode, gateway, checkSession, action, gatewayName,
  } = d;
  if (checkSession) {
    return formIntentUrl(pathMap.checkSession, {
      broker,
      openMode,
      gateway,
      gatewayName,
      action,
      ...ENV === 'staging' && { staging: true },
    });
  }
  switch (d.intent) {
    case NonTransactionalIntent.SEE_ORDERS:
      if (d.loginIntentURL) {
        return formLoginIntentUrl(pathMap.seeOrders, {
          broker, openMode, gateway,
        });
      }
      return formIntentUrl(pathMap.seeOrders, {
        broker, openMode, gateway,
      });
    case NonTransactionalIntent.BROKER_LOGOUT:
      if (d.loginIntentURL) {
        return formLoginIntentUrl(pathMap.brokerLogout, {
          broker, openMode, gateway,
        });
      }
      return formIntentUrl(pathMap.brokerLogout, {
        broker, openMode, gateway,
      });
    case TransactionIntent.CONNECT:
    case TransactionIntent.TRANSACTION:
    case TransactionIntent.HOLDINGS_IMPORT:
    case TransactionIntent.SUBSCRIPTION:
    default:
      if (d.loginIntentURL) {
        return formLoginIntentUrl(`${pathMap.transaction}${d.transactionId}`, {
          broker, openMode, gateway, transactionId: d.transactionId,
        });
      }
      return formIntentUrl(`${pathMap.transaction}${d.transactionId}`, {
        broker, openMode, gateway, transactionId: d.transactionId,
      });
  }
}

export type BrokerConfig = {
  name: string;
  getIntentURL: (urlParams: any) => string;
  getLeprechaunIntentURL: (urlParams: any) => string;
  skipLogin: boolean;
  loginOpenMode: WindowType;
  transactionOpenMode: WindowType;
}

const brokerMap: {[key: string]: BrokerConfig} = {
  axis: {
    name: 'axis',
    getIntentURL: function (urlParams) {
      return `${AXIS_PLATFORM_URL}${getIntentURL({
        ...urlParams, loginIntentURL: true,
      })}`;
    },
    getLeprechaunIntentURL: function (urlParams) {
      return AXIS_URL_LEPRECHAUN + getIntentURL({ ...urlParams, loginIntentURL: true });
    },
    skipLogin: true,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.NEWTAB,
  },

  kite: {
    name: 'zerodha',
    getIntentURL: function (urlParams) {
      return `${KITE_PLATFORM_URL}${getIntentURL({
        ...urlParams,
      })}`;
    },
    getLeprechaunIntentURL: function (urlParams) {
      return `${KITE_PLATFORM_URL}${getIntentURL({
        ...urlParams,
      })}`;
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },

  aliceblue: {
    name: 'aliceblue',
    getIntentURL: function (urlParams) {
      return ALICEBLUE_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return ALICEBLUE_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.NEWTAB,
  },

  edelweiss: {
    name: 'edelweiss',
    getIntentURL: function (urlParams) {
      return EDELWEISS_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return EDELWEISS_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },

  hdfc: {
    name: 'hdfc',
    getIntentURL: function (urlParams) {
      return `${HDFC_PLATFORM_URL}${getIntentURL({
        ...urlParams, loginIntentURL: true,
      })}`;
    },
    getLeprechaunIntentURL: function (urlParams) {
      return HDFC_URL_LEPRECHAUN + getIntentURL({ ...urlParams, loginIntentURL: true });
    },
    skipLogin: true,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.NEWTAB,
  },

  fivepaisa: {
    name: '5paisa',
    getIntentURL: function (urlParams) {
      return FIVEPAISA_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return FIVEPAISA_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },

  motilal: {
    name: 'motilal',
    getIntentURL: function (urlParams) {
      return MOTILAL_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return MOTILAL_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },

  kotak: {
    name: 'kotak',
    getIntentURL: function (urlParams) {
      return KOTAK_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return KOTAK_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.NEWTAB,
  },

  iifl: {
    name: 'IIFL Sec',
    getIntentURL: function (urlParams) {
      return IIFL_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return IIFL_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },

  trustline: {
    name: 'Trustline',
    getIntentURL: function (urlParams) {
      return TRUSTLINE_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return TRUSTLINE_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },

  upstox: {
    name: 'Upstox',
    getIntentURL: function (urlParams) {
      return UPSTOX_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return UPSTOX_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },

  angelbroking: {
    name: 'Angel Broking',
    getIntentURL: function (urlParams) {
      return ANGELBROKING_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return ANGELBROKING_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },

  groww: {
    name: 'Groww',
    getIntentURL: function (urlParams) {
      return GROWW_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return GROWW_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },

  icici: {
    name: 'icici',
    getIntentURL: function (urlParams) {
      return ICICI_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return ICICI_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.NEWTAB,
  },

  fundzbazar: {
    name: 'fundzbazar',
    getIntentURL: function (urlParams) {
      return FUNDZBAZAR_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return FUNDZBAZAR_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },

  dhan: {
    name: 'dhan',
    getIntentURL: function (urlParams) {
      return DHAN_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return DHAN_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },

  fisdom: {
    name: 'Fisdom',
    getIntentURL: function (urlParams) {
      return FISDOM_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    getLeprechaunIntentURL: function (urlParams) {
      return FISDOM_PLATFORM_URL + getIntentURL({
        ...urlParams,
      });
    },
    skipLogin: false,
    loginOpenMode: WindowType.NEWTAB, // 'iframe' or 'newtab'
    transactionOpenMode: WindowType.IFRAME,
  },
};

export { getEncodedPathParams as getEncodedParams };
export default brokerMap;
