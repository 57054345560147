import { isSdkReady } from '../../utils/sdkReadiness';
import { store } from '../../utils/userStore';

/**
 * Gives the current status of the user.
 * @this {import('../../scdk').default}
   @returns {Promise<'GUEST'|'UNCONNECTED'|'CONNECTED'>}
 */
function getStatus() {
  return isSdkReady().then(() => ({
    status: store.status,
  }));
}

export default getStatus;
