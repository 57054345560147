/* eslint-disable no-unused-vars */
import { getRiskLabel } from '../../utils';
import httpHandler, { RequestType } from '../../services/apiService';
import apiMap from '../../utils/apiMap';
import { SmallcaseData as ScData } from '../../types/smallcase';
import { SmallcaseResponseType } from '../../types/apiResponses/getSmallcases';
import { isSdkReady } from '../../utils/sdkReadiness';

type SmallcaseData = ScData & {
  riskLabel: string;
  imageUrl: string;
};

/**
 * @this {import('../../scdk').default}
 * @param {Object} args
 * @param {string} args.sortBy - **Optional** Default Value: 'default'.
 * Property on basis of which smallcase will be sorted. Possible values of SortBy can be:
 * 'dailyReturns', 'weeklyReturns', 'monthlyReturns', 'yearlyReturns',
 * 'indexValue', 'minInvestAmount', 'cagr', 'risk',
 * 'sharpe', 'inceptionDate', 'uploadedDate', 'nextRebalanceDate'
 * @param {number} args.sortOrder - **Optional** Default Value: 1.
 * Property defines the sort order. Possible values of sortOrder can be 1 or -1
 * @param {string} args.filterBy - **Comming Soon**
 * @returns {Promise<Object[]>} smallcaseArray
 */
function getSmallcases({ sortBy = 'default', sortOrder = 1 } = {}) {
  // eslint-disable-next-line max-len
  return isSdkReady() // Once the session is made only then the next call will happen.
    .then(() =>
      httpHandler<SmallcaseResponseType>(RequestType.GET, apiMap.SC_DISCOVERY, {
        sortBy,
        sortOrder,
      }),
    )
    .then((res) => res.data)
    .then(({ smallcases }) => ({
      smallcases: smallcases.map((smallcase: SmallcaseData) => {
        smallcase.riskLabel = getRiskLabel(smallcase.stats.ratios.risk);
        smallcase.imageUrl = `https://assets.smallcase.com/images/smallcases/200/${smallcase.scid}.png`;
        return smallcase;
      }),
    }));
}

export default getSmallcases;
