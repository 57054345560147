import WindowManager from '../utils/windowManager';
import BaseLasStrategy from './BaseLasStrategy';
import lasRunner from './LasRunnerUtil';
import { listenForClientMessage } from './utils';
import ScLoanError, { lasErrorInstanceMap } from './utils/scLoanError';

// keeping iframeElement oustide function to check if dashboard is being opened more than once
let iframeElem: HTMLIFrameElement;
const iframeId = 'lamf-dashboard-iframe';
let overlayDiv: HTMLDivElement;

type ResizeMessage = {
  type: 'UPDATE_FRAME_HEIGHT';
  payload: DOMRect;
};
type ToggleOverlayMessage = {
  type: 'SHOW_OVERLAY' | 'HIDE_OVERLAY';
};

type DashboardMessage = ResizeMessage | ToggleOverlayMessage;

const messageHandler = (e: MessageEvent<DashboardMessage>) => {
  const { data } = e;
  if (data.type === 'UPDATE_FRAME_HEIGHT') {
    iframeElem.style.height = `${data.payload.height}px`;
  }
  if (data.type === 'SHOW_OVERLAY') {
    overlayDiv.style.display = 'block';
    iframeElem.style.zIndex = '2147483646';
  }
  if (data.type === 'HIDE_OVERLAY') {
    overlayDiv.style.display = 'none';
    iframeElem.style.zIndex = '';
  }
};

const removeIframe = () => {
  if (iframeElem) {
    iframeElem.parentNode?.removeChild(iframeElem);
    window.removeEventListener('message', messageHandler);
    iframeElem = undefined;
  }
  if (overlayDiv) {
    overlayDiv.parentNode?.removeChild(overlayDiv);
    overlayDiv = undefined;
  }
};

const injectIframe = (containerElem: HTMLElement, url: string) => {
  // calling this method after navigating away can lead to reference being present but element not in dom
  if (iframeElem) {
    removeIframe();
  }
  iframeElem = document.createElement('iframe');
  iframeElem.id = iframeId;
  iframeElem.style.cssText = `min-width:100%;min-height:100vh;position:relative;background:white;outline:0;border:0;padding:0;margin:0;z-index:auto;`;
  iframeElem.src = url;
  const pageLoadPromise = new Promise<void>((res) => {
    iframeElem.onload = () => res();
  });

  overlayDiv = document.createElement('div');
  overlayDiv.style.cssText = `position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.8);z-index:2147483645;display:none;`;

  window.addEventListener('message', messageHandler);

  containerElem.append(overlayDiv);
  containerElem.append(iframeElem);

  return pageLoadPromise;
};

class OpenDashboardStrategy extends BaseLasStrategy {
  private containerElem: HTMLElement;

  constructor(interactionToken: string, targetElemId) {
    super(interactionToken);
    this.containerElem = document.getElementById(targetElemId);
    if (iframeElem && document.body.contains(iframeElem))
      throw lasErrorInstanceMap.DASHBOARD_ALREADY_OPEN;
  }

  async runInteraction() {
    // setup for listeners to resolve on postmessage from las_client
    const listenerPromise = listenForClientMessage(this.interactionDetails.url);

    await injectIframe(this.containerElem, this.interactionDetails.url);

    // close loader
    await WindowManager.closeSecondary();

    // wait for listeners to get some message from client
    const clientMesssage = await listenerPromise;
    removeIframe();

    const clientPayload = clientMesssage.data.payload;
    if (clientPayload.code !== 0) {
      throw new ScLoanError(clientPayload.message, clientPayload.code);
    }
  }

  public exitInteraction() {
    removeIframe();
    return this.handleInteractionError(lasErrorInstanceMap.USER_CANCELLED);
  }
}

type OpenDashboardArgs = {
  interactionToken?: string;
  target?: string;
};
function openDashboard({ interactionToken, target }: OpenDashboardArgs = {}) {
  const intentStrategy = new OpenDashboardStrategy(interactionToken, target);
  return {
    close: intentStrategy.exitInteraction.bind(intentStrategy),
    task: lasRunner(intentStrategy),
  };
}

export default openDashboard;
