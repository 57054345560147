import httpHandler, { RequestType } from '../../services/apiService';
import apiMap from '../../utils/apiMap';
import errorMap from '../../utils/errorMap';
import { UserInvestmentsResponse } from '../../types/apiResponses/userInvestments';
import { isSdkReady } from '../../utils/sdkReadiness';

/**
 * @returns {Promise<Object[]>} investedSmallcase
 */
const getUserInvestmentDetails = function investments({
  iscid,
}: { iscid?: string } = {}) {
  return isSdkReady()
    .then(() => {
      if (!iscid) {
        throw errorMap.INVALID_ISCID;
      }
      return httpHandler<UserInvestmentsResponse>(
        RequestType.GET,
        apiMap.INVESTMENTS,
        {
          iscids: [iscid],
        },
      );
    })
    .then((res) => res.data)
    .then((investmentsRes) =>
      investmentsRes.map((smallcase) => {
        smallcase.investment.imageUrl = `https://assets.smallcase.com/images/smallcases/200/${smallcase.investment.scid}.png`;
        return smallcase;
      }),
    );
};

export default getUserInvestmentDetails;
