import axios from 'axios';
import {
  NetworkRequestFailedError,
  BrokerNotConnectedError,
} from '../utils/customErrorTypes';
import { store } from '../utils/userStore';
import apiMap from '../utils/apiMap';

export const enum RequestType {
  GET = 'GET',
  POST = 'POST',
}

type RequestHandler = <T>(
  type: RequestType,
  endpoint: string,
  data?: any,
  configHeaders?: any,
) => Promise<T>;

function httpRequest(): RequestHandler {
  const configration = {
    baseURL: BASE_URL,
  };
  const headers = {
    'Content-Type': 'application/json',
  };

  const requestHandler = axios.create(configration);
  requestHandler.interceptors.request.use(
    (config) => {
      const { token } = store;
      if (token) config.headers['x-sc-gateway'] = token;
      delete config.xsrfCookieName;
      delete config.xsrfHeaderName;
      return config;
    },
    (error) => Promise.reject(error),
  );
  return function (type, endpoint, data = {}, configHeaders = {}) {
    const URL = `${store.gateway}${endpoint}`;
    return requestHandler(URL, {
      method: type,
      withCredentials: true,
      headers: {
        'x-sc-csrf': store.CSRF,
        ...headers,
        ...configHeaders,
      },
      [type === RequestType.GET ? 'params' : 'data']: data,
    })
      .then((res) => {
        if (endpoint === apiMap.SESSION_INIT || endpoint === apiMap.CONNECT) {
          store.token = res.data.data.gatewayToken;
        }
        return res.data;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          throw new BrokerNotConnectedError(err.response);
        }
        throw new NetworkRequestFailedError(err.response);
      });
  };
}

const httpHandler = httpRequest();

export default httpHandler;
