import BaseLasStrategy from './BaseLasStrategy';
import lasRunner from './LasRunnerUtil';

class TriggerLoanJourneyStrategy extends BaseLasStrategy {}

type TriggerLoanJourneyArgs = {
  interactionToken?: string;
};
async function triggerLoanJourney({
  interactionToken,
}: TriggerLoanJourneyArgs = {}) {
  const intentStrategy = new TriggerLoanJourneyStrategy(interactionToken);
  return lasRunner(intentStrategy);
}

export default triggerLoanJourney;
