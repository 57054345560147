import TransactionalIntent from '../../../constants/transactionIntents';
import NonTransactionalIntent from '../../../constants/nonTransactionalIntents';
import defaultIntentHandler from './defaultIntentHandler';
import transactionIntentHandler from './transactionIntentHandler';
import connectIntentHandler from './connectIntentHandler';
import holdingsImportIntentHandler from './holdingsImportIntentHandler';
import seeOrdersIntentHandler from './seeOrdersIntentHandler';
import subscriptionIntentHandler from './subscriptionIntentHandler';
import brokerLogoutIntentHandler from './brokerLogoutIntentHandler';
import { IntentHandler } from '../intentHandlerTypes';

// for triggerTransaction, where intent can be any one
function getIntentHandler(
  transactionIntent: TransactionalIntent | NonTransactionalIntent,
): IntentHandler {
  switch (transactionIntent) {
    case TransactionalIntent.TRANSACTION:
      return {
        ...defaultIntentHandler,
        ...transactionIntentHandler,
      };
    case TransactionalIntent.SUBSCRIPTION:
      return {
        ...defaultIntentHandler,
        ...subscriptionIntentHandler,
      };
    case TransactionalIntent.CONNECT:
      return {
        ...defaultIntentHandler,
        ...connectIntentHandler,
      };
    case TransactionalIntent.HOLDINGS_IMPORT:
      return {
        ...defaultIntentHandler,
        ...holdingsImportIntentHandler,
      };
    case NonTransactionalIntent.SEE_ORDERS:
      return {
        ...defaultIntentHandler,
        ...seeOrdersIntentHandler,
      };
    case NonTransactionalIntent.BROKER_LOGOUT:
      return {
        ...defaultIntentHandler,
        ...brokerLogoutIntentHandler,
      };
    default:
      return defaultIntentHandler;
  }
}
export default getIntentHandler;
