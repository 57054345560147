const SENTRY_HOST = 'sentry.smallcase.com';
const SENTRY_PROJECT = 24;
const SENTRY_VERSION = 7;
const SENTRY_CLIENT = 'custom-client@0.0.1';
const SENTRY_KEY = '1d5680ea1cdf48659f6472b7aec24520';

export {
  SENTRY_HOST,
  SENTRY_PROJECT,
  SENTRY_VERSION,
  SENTRY_CLIENT,
  SENTRY_KEY,
};
