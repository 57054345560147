const apiMap = {
  CANCEL_BATCH: '/user/cancelBatch',
  CHART: '/smallcase/chart',
  CONNECT: '/smallcaseConnect',
  EXITED: '/user/exitedSmallcases',
  INVESTMENTS: '/user/investments',
  MARKET_STATUS: '/market/checkStatus',
  MARK_TRANSACTION: '/transaction/markErrored',
  NEWS: '/smallcase/news',
  ORDERS: '/user/orders',
  SC_DISCOVERY: '/smallcases',
  SC_PROFILE: '/smallcase',
  SESSION_INIT: '/initSession',
  SIP: '/user/sip',
  TRANSACTION_STATUS: '/transaction',
  UPDATE: '/transaction/update',
};

/*
  NOTE: APIs WRITTEN IN ALPHABETICAL ORDER to find them easily.
  Keys can be obtained in the right order using the following logic
  Object.keys(apiMap).sort().reduce((acc, key) => (acc + `${key}: '${apiMap[key]}',\n`), '')
*/

export default apiMap;
