// API Txn status
const transactionStatus = {
  COMPLETED: 'COMPLETED',
  PROCESSING: 'PROCESSING',
  INITIALIZED: 'INITIALIZED',
  USED: 'USED',
  ERRORED: 'ERRORED',
  ACTION_REQUIRED: 'ACTION_REQUIRED',
};

export const completionStatus = {
  ERRORED: 'ERRORED',
};


export const transactionTypes = {
  SECURITIES: 'SECURITIES',
};

export const featureMap = {
  CONNECT: 'connect',
  HOLDINGS_IMPORT: 'holdingsImport',
  TRANSACTION_SST: 'sst',
  TRANSACTION_SMT: 'smt',
  SEE_ORDERS: 'showOrders',
  BROKER_LOGOUT: 'brokerLogout',
  CANCEL_ORDER: 'cancelOrder',
  // valid brokers for SUBSCRIPTION will be same as that of SMT
  SUBSCRIPTION: 'smt',
};

export const userMismatch = 'user_mismatch';

export const alreadySubscribed = 'already_subscribed';

export default transactionStatus;
