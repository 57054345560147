import BaseLasStrategy from './BaseLasStrategy';
import lasRunner from './LasRunnerUtil';

class RepaymentStrategy extends BaseLasStrategy {
  constructor(interactionToken: string) {
    super(interactionToken);
    this.interactionParams = {
      ...this.interactionParams,
      connectFramePath: 'smallcase-loader',
    };
  }
}

type MakeRepaymentArgs = {
  interactionToken?: string;
};
async function makeRepayment({ interactionToken }: MakeRepaymentArgs = {}) {
  const intentStrategy = new RepaymentStrategy(interactionToken);
  return lasRunner(intentStrategy);
}

export default makeRepayment;
