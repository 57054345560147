import TransactionIntent from '../../../constants/transactionIntents';
import { Transaction } from '../../../types/transaction';

export const enum TransactionPlatform {
  BROKER_PLATFORM = 'BROKER_PLATFORM',
  MF_PLATFORM = 'MF_PLATFORM',
  GW_FE_CLIENT = 'GW_FE_CLIENT',
}

export const getTransactionPlatform = ({
  intent,
  orderConfig,
}: Transaction) => {
  if (intent === TransactionIntent.MF_HOLDINGS_IMPORT)
    return TransactionPlatform.GW_FE_CLIENT;
  if (orderConfig?.assetUniverse === 'MUTUAL_FUND')
    return TransactionPlatform.MF_PLATFORM;
  return TransactionPlatform.BROKER_PLATFORM;
};
