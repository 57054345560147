import BaseLasStrategy from './BaseLasStrategy';

const lasRunner = async <T extends BaseLasStrategy>(lasStrategy: T) => {
  try {
    await lasStrategy.initInteraction();
    await lasStrategy.runInteraction();
  } catch (e) {
    return lasStrategy.handleInteractionError(e);
  }
  return lasStrategy.finishInteraction();
};

export default lasRunner;
