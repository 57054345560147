import {
  getHtmlElement,
  getIframeElementWithDefaultStyles,
  listenMessageEvent,
  notifyConnectWithGatewayName,
  prepareMiddleIFrame,
} from '.';
import { getMiddleFrameURL } from '../constants';
import PostMessageType from '../constants/postMessageTypes';
import { backupActiveElement, restoreActiveElement } from './activeElement';
import { store } from './userStore';
import WindowManager from './windowManager';

let connectIframePromise: Promise<void> = null;

/**
 * Ensures that the connect frame is injected into the DOM and listens for messages.
 * If the connect frame is already injected, it returns the existing promise.
 * @returns A promise that resolves when the connect frame is loaded and ready.
 */
export const ensureConnectFrameInjected = async () => {
  if (connectIframePromise) return connectIframePromise;
  const middleFrameURL = getMiddleFrameURL(store.gateway);
  const middleFrameIframe = prepareMiddleIFrame(middleFrameURL);

  const referenceElement = getHtmlElement(WindowManager.elementOrSelector);
  referenceElement.appendChild(middleFrameIframe);

  connectIframePromise = listenMessageEvent(
    PostMessageType.CONNECT_LOADED,
  ).promise.then((event) => {
    notifyConnectWithGatewayName(store.displayName);
    store.thirdPartyCookieSupported = event.data.thirdPartyCookieSupported;

    window.addEventListener('message', (ev) => {
      // discard any message not sent by connect iframe
      if (ev.origin !== MIDDLE_FRAME_URL) return;

      if (ev.data.type === PostMessageType.SHOW_FRAME) {
        middleFrameIframe.style.visibility = 'unset';
        middleFrameIframe.style.display = 'unset';
        backupActiveElement();
        referenceElement.style.overflow = 'hidden';
      } else if (ev.data.type === PostMessageType.HIDE_FRAME) {
        middleFrameIframe.style.visibility = 'hidden';
        middleFrameIframe.style.display = 'none';
        restoreActiveElement();
        referenceElement.style.overflow = '';
      }
    });
  });
  return connectIframePromise;
};

/** create an iframe to embed a client url */
export const prepareGwClientIframe = () => {
  const iframeElement = getIframeElementWithDefaultStyles();
  iframeElement.style.zIndex = '+2147483647'; // safe highest allowed value
  iframeElement.classList.add('scdk-gw-client-frame');
  iframeElement.allow = 'otp-credentials;geolocation;camera';
  const referenceElement = getHtmlElement(WindowManager.elementOrSelector);
  referenceElement.appendChild(iframeElement);
  return iframeElement;
};

/**
 * instance of the iframe in which UI for a gateway client app us shown
 */
let gwClientIframe: HTMLIFrameElement = null;

/**
 * initialises gwClientIframe if not present and returns it
 * @returns instance of gwClientIframe
 */
export const getGwClientIframe = () => {
  if (!gwClientIframe) gwClientIframe = prepareGwClientIframe();
  // reattach iframe if removed
  if (!gwClientIframe.parentNode) {
    const referenceElement = getHtmlElement(WindowManager.elementOrSelector);
    referenceElement.appendChild(gwClientIframe);
  }
  return gwClientIframe;
};

/** load the client url in iframe,
 * resolves once the page load is done do minimise flickers */
export const openUrlInIframe = (
  iframeElement: HTMLIFrameElement,
  url: string,
) =>
  new Promise<void>((resolve) => {
    const iframeCopy = iframeElement;
    iframeCopy.setAttribute('src', url);
    iframeCopy.onload = () => resolve();
    iframeCopy.style.visibility = 'unset';
    iframeCopy.style.display = 'unset';
    backupActiveElement();
    iframeCopy.style.overflow = 'hidden';
  });

/** remove the client from view  */
export const closeIframe = (iframeElement: HTMLIFrameElement) => {
  const iframeCopy = iframeElement;
  iframeCopy.setAttribute('src', 'about:blank');
  iframeCopy.style.visibility = 'hidden';
  iframeCopy.style.display = 'none';
  restoreActiveElement();
  iframeCopy.style.overflow = '';
  iframeCopy.parentNode.removeChild(gwClientIframe);
};
