import makeRepayment from '../las/makeRepayment';
import openDashboard from '../las/openDashboard';
import triggerLoanJourney from '../las/triggerLoanJourney';
import { setupAskOriginResponse } from '../las/utils';
import withdrawPrincipal from '../las/withdrawPrincipal';
import { ensureConnectFrameInjected } from '../utils/iframe';
import { store } from '../utils/userStore';

class ScLoan {
  // eslint-disable-next-line no-use-before-define
  private static _lasSdkInstance: ScLoan;

  constructor({ gatewayName }) {
    if (ScLoan._lasSdkInstance !== undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        "this SDK follows Singleton Pattern, thus doesn't allow creation of multiple instances and if the constructor is called multiple times, the first instance is returned each time.",
      );
      // eslint-disable-next-line no-constructor-return
      return ScLoan._lasSdkInstance;
    }
    ScLoan._lasSdkInstance = this;
    store.loanGateway = gatewayName;
    setupAskOriginResponse();
    ensureConnectFrameInjected();
  }
}

interface ScLoan {
  apply: typeof triggerLoanJourney;
  pay: typeof makeRepayment;
  withdraw: typeof withdrawPrincipal;
  service: typeof openDashboard;
}

ScLoan.prototype.apply = triggerLoanJourney;
ScLoan.prototype.pay = makeRepayment;
ScLoan.prototype.withdraw = withdrawPrincipal;
ScLoan.prototype.service = openDashboard;

export default ScLoan;
