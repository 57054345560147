import httpHandler, { RequestType } from '../../services/apiService';
import apiMap from '../../utils/apiMap';
import { UserInvestmentsResponse } from '../../types/apiResponses/userInvestments';
import { isSdkReady } from '../../utils/sdkReadiness';

/**
 * @returns {Promise<Object>} investedSmallcase
 */
const getUserInvestments = function investments({
  iscids = [],
}: {
  iscids?: string[];
} = {}) {
  return isSdkReady()
    .then(() =>
      httpHandler<UserInvestmentsResponse>(
        RequestType.GET,
        apiMap.INVESTMENTS,
        {
          iscids,
        },
      ),
    )
    .then((res) => res.data)
    .then((investmentsRes) =>
      investmentsRes.map((smallcase) => {
        smallcase.investment.imageUrl = `https://assets.smallcase.com/images/smallcases/200/${smallcase.investment.scid}.png`;
        return smallcase;
      }),
    );
};

export default getUserInvestments;
