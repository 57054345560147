import { Transaction } from '../../../types/transaction';
import { to, wait } from '../../../utils';
import {
  handleTransactionApiErrors,
  verifyTransactionStatus,
} from '../../../utils/transactionValidation';
import WindowManager, { ChangeTarget } from '../../../utils/windowManager';
import { fetchTransactionStatus } from '../transactionHelpers';

export async function fetchTransactionDetails(
  transactionId: string,
): Promise<Transaction> {
  const transactionPromise = fetchTransactionStatus(transactionId);
  /** waits to show pre-connect screen */

  // todo replace with generic loader
  await WindowManager.openIndirect({
    changeOnly: ChangeTarget.SECONDARY,
    secondaryStatus: 'smallcase-loader',
  });
  const loadingScreenPromise = wait(2000);
  const [transactionPromiseError, response] = await to(transactionPromise);
  await loadingScreenPromise;
  await handleTransactionApiErrors(transactionPromiseError);
  const { transaction } = response;
  await verifyTransactionStatus(transaction);
  return transaction;
}
