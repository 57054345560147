/**
 * Below storage utils are copied from @smallcase/utils/browser
 * Reason: gitlab ci will not work with smallcase npm registry without utils vpn
 */

/**
 * Utility function to safely get item from local / session storage
 * @param {string} key Key of the item to be retrived
 * @param {('local' | 'session')} type Type of storage 'local' / 'session'
 * @param {function} [errorCallback] Callback to be execute if any error is encountered
 * @returns {(string | undefined)} Returns retrived item string or undefined
 * if not found or in case of any error
 */
export function getStorageItem(
  key: string,
  type: 'session' | 'local',
  errorCallback?: (err: Error) => void,
) {
  try {
    if (typeof key !== 'string') {
      throw Error('Key to be retrived should always be a string');
    }
    switch (type) {
      case 'session':
        return sessionStorage.getItem(key);
      case 'local':
        return localStorage.getItem(key);
      default:
        throw Error('Invalid storage type passed');
    }
  } catch (err) {
    if (typeof errorCallback === 'function') {
      errorCallback(err);
    }
    return undefined;
  }
}

/**
 * Utility function to safely store item in local / session storage
 * @param {string} key Key of the item to be stored
 * @param {string} value Value of the item to be stored
 * @param {('local' | 'session')} type Type of storage 'local' / 'session'
 * @param {function} [errorCallback] Callback to be execute if any error is encountered
 * @returns {boolean} Returns true if the items is succesfully stored, otherwise false
 */
export function setStorageItem(
  key: string,
  value: string,
  type: 'session' | 'local',
  errorCallback?: (err: Error) => void,
) {
  try {
    if (typeof value !== 'string' || typeof key !== 'string') {
      throw Error('Value and Key to be stored should always be a string');
    }
    switch (type) {
      case 'session':
        sessionStorage.setItem(key, value);
        break;
      case 'local':
        localStorage.setItem(key, value);
        break;
      default:
        throw Error('Invalid storage type passed');
    }
    return true;
  } catch (err) {
    if (typeof errorCallback === 'function') {
      errorCallback(err);
    }
    return false;
  }
}
