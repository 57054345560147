import httpHandler, { RequestType } from '../../services/apiService';
import apiMap from '../../utils/apiMap';
import errorMap from '../../utils/errorMap';
import { NewsResponse } from '../../types/apiResponses/news';
import { isSdkReady } from '../../utils/sdkReadiness';

/**
 * @this {import('../../scdk').default}
 * @param {Object} args
 * @param {string} args.scid
 * @returns {Promise<Array>} newsObject
 */
function getNews({
  scid,
  iscid,
  offset = 0,
  count = 15,
}: {
  scid?: string;
  iscid?: string;
  offset?: number;
  count?: number;
} = {}) {
  if (!scid && !iscid) {
    return Promise.reject(errorMap.INVALID_SCID);
  }
  return isSdkReady()
    .then(() =>
      httpHandler<NewsResponse>(RequestType.GET, apiMap.NEWS, {
        scid,
        iscid,
        offset,
        count: count + 1,
      }),
    )
    .then((res) => res.data)
    .then(({ news }) => {
      news = news || [];
      const loadMore = news.length === count + 1;
      if (loadMore) {
        news.length = count;
      }
      return {
        news,
        count: news.length,
        loadMore,
        offset,
      };
    });
}

export default getNews;
