import { getAgent, to } from '../../utils';
import errorMap from '../../utils/errorMap';
import {
  handleTransactionApiErrors,
  verifyTransactionStatus,
} from '../../utils/transactionValidation';
import WindowManager, { ChangeTarget } from '../../utils/windowManager';
import {
  fetchTransactionStatus,
  throwError,
  updateTransaction,
} from './transactionHelpers';
import TransactionIntent from '../../constants/transactionIntents';
import executeMfTransaction from './executeMfTransaction';
import { TransactionPlatform } from './utils/getTransactionPlatform';
import type scDK from '../../sdk/main';
import { isSdkReady } from '../../utils/sdkReadiness';

type TriggerMfTransactionArgs = {
  /** transactionId for the transaction */
  transactionId: string;
};
/**
 * start a transaction for the mutualfunds asset type
 */
async function triggerMfTransaction(
  this: scDK,
  { transactionId }: TriggerMfTransactionArgs,
) {
  // check if transactionId is present
  if (!transactionId || typeof transactionId !== 'string') {
    await throwError({
      markTransaction: false,
      error: errorMap.INVALID_TRANSACTION,
    });
  }
  // all sdk methods that make api calls should ensure that initPromise is resolved
  await isSdkReady();

  // show loading screen while api resolves
  WindowManager.openIndirect({
    changeOnly: ChangeTarget.SECONDARY,
    secondaryStatus: 'smallcase-loader',
  });

  // get initial transactionstatus
  const [fetchStatusError, statusResponse] = await to(
    fetchTransactionStatus<TransactionIntent.MF_HOLDINGS_IMPORT>(transactionId),
  );
  const { transaction } = statusResponse;
  // verify transaction api response
  if (transaction.intent !== TransactionIntent.MF_HOLDINGS_IMPORT) {
    await throwError({
      markTransaction: false,
      error: errorMap.INVALID_TRANSACTION,
    });
  }
  await handleTransactionApiErrors(fetchStatusError);
  await verifyTransactionStatus(transaction);

  // update user agent
  const agent = getAgent();
  to(updateTransaction({ transactionId, agent }));

  return executeMfTransaction(transaction, TransactionPlatform.GW_FE_CLIENT);
}

export default triggerMfTransaction;
