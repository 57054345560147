import PostMessageType from '../../constants/postMessageTypes';
import transactionStatus, {
  completionStatus,
} from '../../constants/transactionStatus';
import { Transaction } from '../../types/transaction';
import { to } from '../../utils';
import errorMap, { apiErrorCodeMap, getErrorObj } from '../../utils/errorMap';
import {
  closeIframe,
  getGwClientIframe,
  openUrlInIframe,
} from '../../utils/iframe';
import { store } from '../../utils/userStore';
import WindowManager, { ChangeTarget } from '../../utils/windowManager';
import {
  addTransactionListeners,
  fetchTransactionStatus,
  handleUserCancelledErrors,
  throwError,
} from './transactionHelpers';
import { TransactionPlatform } from './utils/getTransactionPlatform';

type MfPlatform =
  | TransactionPlatform.GW_FE_CLIENT
  | TransactionPlatform.MF_PLATFORM;

const generatePlatformUrlAndOrigin = (
  platform: TransactionPlatform,
  transaction: Transaction,
  gateway: string,
) => {
  switch (platform) {
    case TransactionPlatform.MF_PLATFORM: {
      if (!transaction.platform) return undefined;
      return transaction.platform;
    }
    case TransactionPlatform.GW_FE_CLIENT:
      return {
        origins: GW_FE_URL,
        url: `${GW_FE_URL}/mutualfunds/import?transactionId=${transaction.transactionId}&gateway=${gateway}&clientType=web`,
      };
    default:
      return undefined;
  }
};

const executeMfTransaction = async (
  transactionInitStatus: Transaction,
  platform: MfPlatform,
) => {
  const { transactionId } = transactionInitStatus;
  const platformUrl = generatePlatformUrlAndOrigin(
    platform,
    transactionInitStatus,
    store.gateway,
  );
  if (!platformUrl) {
    await throwError({
      markTransaction: true,
      transactionId,
      errorObj: apiErrorCodeMap[2000],
    });
  }

  // setup for listeners to resolve on postmessage from mf_platform
  const listenerPromise = addTransactionListeners(
    { broker: 'NA' },
    platformUrl.origins,
  );

  // open mf_platform
  const mfPlatformFrame = getGwClientIframe();
  await openUrlInIframe(mfPlatformFrame, platformUrl.url);

  // close loader once mf_platform is loaded
  WindowManager.closeSecondary();

  // wait for listeners to get some message from mf_platform
  const message = await listenerPromise;

  // remove transaction ui as soon as possible
  closeIframe(mfPlatformFrame);

  // show loading screen while api resolves
  WindowManager.openIndirect({
    changeOnly: ChangeTarget.SECONDARY,
    secondaryStatus: 'smallcase-loader',
  });

  // get latest transactionstatus
  const [fetchStatusError, statusResponse] = await to(
    fetchTransactionStatus(transactionId),
  );

  // throw is api call failed
  if (fetchStatusError) {
    await throwError({
      markTransaction: false,
      error: errorMap.API_ERROR,
    });
  }

  // identify outcome of transaction from postmessage data and transaction data
  const { transaction } = statusResponse;
  const hasUserCancelled = [
    PostMessageType.USER_CANCELLED,
    PostMessageType.CLOSED_WINDOW,
  ].includes(message.data.type);
  const isTransactionSuccess = [
    transactionStatus.PROCESSING,
    transactionStatus.COMPLETED,
  ].includes(transaction.status);

  if (!isTransactionSuccess && hasUserCancelled) {
    await handleUserCancelledErrors(transaction);
  }
  // throw if postmessage said transaction errored and update transaction status to ERRORED
  if (message.data.status === completionStatus.ERRORED) {
    await throwError({
      markTransaction: transaction.status !== transactionStatus.ERRORED,
      transactionId,
      errorObj: getErrorObj(message.data.code, message.data.error),
    });
  }

  // close loader once api resolves and no errors are thrown
  // (`throwError` closes all windows otherwise)
  WindowManager.closeSecondary();

  return {
    transactionId: transaction.transactionId,
    ...(Boolean(transaction.success.notes) && {
      notes: transaction.success.notes,
    }),
    ...transaction.success.data,
  };
};

export default executeMfTransaction;
