import { store } from '../../../utils/userStore';
import WindowManager from '../../../utils/windowManager';
import { LEPRECHAUN_SUFFIX } from '../../../constants';
import errorMap, { apiErrorCodeMap } from '../../../utils/errorMap';
import PostMessageType from '../../../constants/postMessageTypes';
import transactionStatus, {
  completionStatus,
} from '../../../constants/transactionStatus';
import {
  fetchBrokerConfig,
  to,
  wait,
  getTransactionOpenMode,
} from '../../../utils';
import {
  addTransactionListeners,
  fetchTransactionStatus,
  throwError,
  pollTransactionStatus,
  handleErrorResponse,
  handleUserCancelledErrors,
  getAuthDataFromTransaction,
} from '../transactionHelpers';
import { IntentHandler } from '../intentHandlerTypes';

const intentHandler: IntentHandler = {
  handleBrokerPlatform: async ({ transaction, broker }) => {
    const brokerConfig = fetchBrokerConfig(broker);
    const transactionOpenMode = getTransactionOpenMode(
      brokerConfig,
      store.thirdPartyCookieSupported,
    );
    const transactionURL = brokerConfig.getIntentURL({
      transactionId: transaction.transactionId,
      broker,
      openMode: transactionOpenMode,
      gateway: store.gateway,
      intent: transaction.intent,
    });
    await WindowManager.closeSecondary();
    const secondaryURL = `holding-import/?broker=${broker.replace(
      LEPRECHAUN_SUFFIX,
      '',
    )}`;
    const completionPromise = addTransactionListeners({
      broker,
      transactionId: transaction.transactionId,
    });
    await WindowManager.openIndirect({
      primaryURL: transactionURL,
      primaryWindowType: transactionOpenMode,
      secondaryStatus: secondaryURL,
      broker,
      intent: transaction.intent,
    });
    return {
      shouldReturn: false,
      completionPromise,
    };
  },
  async handleCompletion({ transactionId, message, broker = store.broker }) {
    const [fetchStatusError, statusResponse] = await to(
      fetchTransactionStatus(transactionId),
    );

    if (fetchStatusError) {
      await throwError({
        markTransaction: false,
        error: errorMap.API_ERROR,
      });
    }

    let { transaction } = statusResponse;
    const userCancelled = [
      PostMessageType.USER_CANCELLED,
      PostMessageType.CLOSED_WINDOW,
    ].includes(message.data.type);
    let returnObj = {};
    if (transaction.status === transactionStatus.PROCESSING) {
      await WindowManager.closePrimary();
      await WindowManager.openIndirect({
        secondaryStatus: `holding-import/?broker=${broker}`,
      });
      const fetchingHoldingScreen = wait(2000);
      transaction = await pollTransactionStatus(transaction.transactionId, 15);
      await fetchingHoldingScreen;
    }

    if (transaction.status === transactionStatus.COMPLETED) {
      await WindowManager.closeAll();
      returnObj = {
        smallcaseAuthToken: transaction.success.smallcaseAuthToken,
        transactionId: transaction.transactionId,
        broker: transaction.success?.broker,
        ...(typeof transaction.success.signup === 'boolean' && {
          signup: transaction.success.signup,
        }),
        success: true,
      };
    } else if (transaction.status === transactionStatus.PROCESSING) {
      await throwError({
        markTransaction: true,
        transactionId: transaction.transactionId,
        errorObj: apiErrorCodeMap[4003],
        data: getAuthDataFromTransaction(transaction),
      });
    } else if (userCancelled) {
      await handleUserCancelledErrors(transaction);
    }

    if (
      message.data.status === completionStatus.ERRORED ||
      transaction.status === transactionStatus.ERRORED
    ) {
      return handleErrorResponse(message, transaction);
    }

    return returnObj;
  },
};

export default intentHandler;
