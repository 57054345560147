export const GatewayLogin = 'gatewayLogin';

export function getMiddleFrameURL(gatewayName: string): string {
  return `${MIDDLE_FRAME_URL}?gateway=${gatewayName}#${TIMESTAMP}`;
}

export function getSmallPlugHost(gatewayName: string): string {
  /**
   TESTCODE - used for local development
   return `https://local.smallcase.com`;
   */
  return `https://${gatewayName}.smallcase.com`;
}

export function getSmallPlugIframeURL(
  gatewayName: string,
  path: string,
  params: string,
  themeColor: string,
): string {
  /**
  TESTCODE - used for local development
  return `https://local.smallcase.com/${path ?? ''}?ptname=${gatewayName}&ct=smallplug_web&${
    params ?? ''
  }`;
  */

  // Format of the URL is always going to be "<gatewayname>.smallcase.com"
  return `https://${gatewayName}.smallcase.com/${path ?? ''}?ct=smallplug_web&color=${encodeURIComponent(themeColor)}&${
    params ?? ''
  }`;
}

export enum ConnectStatus {
  CONNECTED = 'CONNECTED',
  GUEST = 'GUEST',
}

export const LEPRECHAUN_SUFFIX = '-leprechaun';
