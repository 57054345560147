import httpHandler, { RequestType } from '../../services/apiService';
import apiMap from '../../utils/apiMap';
import { store } from '../../utils/userStore';
import {
  ExitedSmallcaseData,
  ExitedSmallcasesResponse,
} from '../../types/apiResponses/exitedSmallcase';
import { isSdkReady } from '../../utils/sdkReadiness';

type ExitedSmallcase = ExitedSmallcaseData & { imageUrl: string };
/**
 * @returns {Promise<Object>} investedSmallcase
 */

const getExitedSmallcases = function exited() {
  return isSdkReady()
    .then(() =>
      httpHandler<ExitedSmallcasesResponse>(
        RequestType.GET,
        apiMap.EXITED,
        store.gateway,
        {},
      ),
    )
    .then((res) => res.data)
    .then((investmentsRes) =>
      investmentsRes.map((smallcase: ExitedSmallcaseData) => {
        const smallcaseWithImg = smallcase as ExitedSmallcase;
        smallcaseWithImg.imageUrl = `https://assets.smallcase.com/images/smallcases/200/${smallcase.scid}.png`;
        return smallcaseWithImg;
      }),
    );
};

export default getExitedSmallcases;
