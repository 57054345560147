import {
  prepareSmallplugIFrame,
  getHtmlElement,
  sendPostMessageToSmallplug,
  listenMessageEvent,
  onMessage,
} from '../../utils';
import { getSmallPlugHost, getSmallPlugIframeURL } from '../../constants';
import postMessageTypes from '../../constants/postMessageTypes';
import transactionIntents from '../../constants/transactionIntents';
import WindowManager from '../../utils/windowManager';
import { store } from '../../utils/userStore';
import triggerTransaction from '../transaction/triggerTransaction';
import signup from '../signup';

let closeSmallplugPromise: Promise<boolean> = new Promise(() => {});

/**
 * Check if iframe exists or not
 * if it exists, remove the iframe
 */
function unmountSmallplugFrame() {
  if (this.referenceElement) {
    if (this.iframeElement) this.iframeElement.remove();
    if (this.closeBtnElement) this.closeBtnElement.remove();
    this.closeBtnElement = undefined;
    this.iframeElement = undefined;
    this.referenceElement.style.overflow = '';
  }
}

function addCloseSmallplugAction(btnElement: HTMLButtonElement) {
  closeSmallplugPromise = new Promise((resolve) => {
    btnElement.addEventListener('click', () => {
      resolve(true);
    });
  });
}

/**
 * check if smallplug iframe exists
 * toggle the iframe visibility to make it visible
 * else, injects smallplug iframe inside the current webpage,
 */
function mountSmallplugFrame({
  path,
  params,
  themeColor,
}: {
  path: string;
  params: string;
  themeColor: string;
}): HTMLElement {
  const smallPlugIframeURL = getSmallPlugIframeURL(
    store.gateway,
    path,
    params,
    themeColor,
  );
  const frameWidth = '100vw';
  const headerHeight = window.innerWidth <= 1183 ? 32 : 0;
  const referenceElement = getHtmlElement(WindowManager.elementOrSelector);
  referenceElement.style.overflow = 'hidden';
  const frameHeight = `${window.innerHeight - headerHeight}px`;
  const smallPlugIframe = prepareSmallplugIFrame(
    smallPlugIframeURL,
    frameHeight,
    frameWidth,
    addCloseSmallplugAction,
    headerHeight,
    themeColor,
  );
  referenceElement.appendChild(smallPlugIframe);
  this.referenceElement = referenceElement;
  this.iframeElement = smallPlugIframe;
  return this.referenceElement;
}

function handleInitialization() {
  sendPostMessageToSmallplug({
    messageType: postMessageTypes.SMALLPLUG_INITIALIZE_RESPONSE,
    messageParams: {
      gatewayToken: store.token,
      gatewayName: store.displayName,
      userStatus: store.status,
      origin: window.location.origin,
    },
  });
}

function handleIntent(data: { intent: string; transactionId: string }): void {
  switch (data.intent) {
    case transactionIntents.LEAD_GEN:
      return signup.call(this).then((res) => {
        sendPostMessageToSmallplug({
          messageType: postMessageTypes.SMALLPLUG_HANDLE_INTENT_RESPONSE,
          messageParams: { data: res, success: true },
        });
      });
    // transaction intent
    case transactionIntents.CONNECT:
    case transactionIntents.TRANSACTION:
    default:
      // internally trigger sdk's triggerTransaction method
      return triggerTransaction
        .call(this, { transactionId: data.transactionId })
        .then((res) =>
          this.init({ smallcaseAuthToken: res.smallcaseAuthToken }).then(() => {
            sendPostMessageToSmallplug({
              messageType: postMessageTypes.SMALLPLUG_HANDLE_INTENT_RESPONSE,
              messageParams: { data: res, success: true },
            });
          }),
        )
        .catch((err) => {
          sendPostMessageToSmallplug({
            messageType: postMessageTypes.SMALLPLUG_HANDLE_INTENT_RESPONSE,
            messageParams: {
              error: {
                message: err.message,
              },
              success: false,
            },
          });
        });
  }
}

/**
 * Opens distribution module inside partner website
 *
 */
async function openSmallplug({
  path = '',
  params = '',
  themeColor = '#2F363F',
}: { path?: string; params?: string; themeColor?: string } = {}): Promise<{
  success: boolean;
  smallcaseAuthToken: string;
}> {
  // wait for SDK initialization to complete, if not already done
  await this.initPromise;
  if (document.querySelector('#scdk-smallplug-frame') !== null) {
    console.log('Smallplug already mounted');
    return {
      success: true,
      smallcaseAuthToken: store.smallcaseAuthToken,
    };
  }
  const smallplugOrigin = getSmallPlugHost(store.gateway);
  // load the smallplug iframe
  mountSmallplugFrame.call(this, { path, params, themeColor });
  // listen for handle Intent
  const initEvent = onMessage(
    postMessageTypes.SMALLPLUG_INITIALIZE,
    handleInitialization,
    smallplugOrigin,
  );
  const intentEvent = onMessage(
    postMessageTypes.SMALLPLUG_HANDLE_INTENT,
    handleIntent.bind(this),
    smallplugOrigin,
  );
  /**
   * listen for message to close smallplug
   * or wait for close promise to resolve
   * (on close button click)
   */
  await Promise.race([
    listenMessageEvent(postMessageTypes.SMALLPLUG_HIDE_FRAME, smallplugOrigin)
      .promise,
    closeSmallplugPromise,
  ]);

  // cancel events
  initEvent.cancel();
  intentEvent.cancel();
  // remove the smallplug iframe
  unmountSmallplugFrame.call(this);
  return {
    success: true,
    smallcaseAuthToken: store.smallcaseAuthToken,
  };
}

export default openSmallplug;
