import NonTransactionalIntent from '../../constants/nonTransactionalIntents';
import { isSdkReady } from '../../utils/sdkReadiness';
import getBroker from '../transaction/utils/getBroker';
import handleLoginRequired from '../transaction/utils/handleLoginRequired';
import seeOrdersIntentHandler from '../transaction/intentHandler/seeOrdersIntentHandler';

async function showOrders({ brokers = [], leprechaun = false } = {}) {
  await isSdkReady();
  // Transaction Handler
  const th = seeOrdersIntentHandler;
  const broker = await getBroker.call(this, {
    brokers,
    leprechaun,
    intent: NonTransactionalIntent.SEE_ORDERS,
  });
  const { completionPromise } = await th.handleBrokerPlatform({ broker });

  const message = await completionPromise;
  const transaction = { intent: NonTransactionalIntent.SEE_ORDERS };
  const { restartTransaction } = await handleLoginRequired.call(this, {
    message,
    broker,
    transaction,
  });
  if (restartTransaction) {
    return showOrders.call(this, {
      brokers,
      leprechaun,
    });
  }

  return th.handleCompletion({ message, broker });
}

export default showOrders;
