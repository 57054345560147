import { unityApiMap } from '../constants/unityApiMap';
import unityApiHandler, {
  craftInteractionHeader,
} from '../services/unityService';
import {
  InteractionDetailsResponse,
  InteractionStatusResponse,
} from '../types/apiResponses/unityResponses';

export const fetchInteractionDetails = async (interactionToken: string) => {
  const response = await unityApiHandler<InteractionDetailsResponse>(
    'POST',
    unityApiMap.INTERACTION_INIT,
    null,
    craftInteractionHeader(interactionToken),
  );
  return response.data;
};

type UpdateInteractionPayload = {
  code: string;
  message: string;
};
export const fetchInteractionStatus = async (
  interactionToken: string,
  payload?: UpdateInteractionPayload,
) => {
  const response = await unityApiHandler<InteractionStatusResponse>(
    'POST',
    unityApiMap.INTERACTION_STATUS,
    payload,
    craftInteractionHeader(interactionToken),
  );
  return response.data;
};

export const triggerWithdrawPrincipal = async (
  interactionToken: string,
  url: string,
) => {
  const response = await unityApiHandler<InteractionDetailsResponse>(
    'POST',
    url,
    null,
    craftInteractionHeader(interactionToken),
  );
  return response.data;
};
