import { store } from '../../../utils/userStore';
import { LEPRECHAUN_SUFFIX } from '../../../constants';
import WindowManager from '../../../utils/windowManager';
import { completionStatus } from '../../../constants/transactionStatus';
import {
  addTransactionListeners,
  handleErrorResponse,
} from '../transactionHelpers';
import { fetchBrokerConfig, getTransactionOpenMode } from '../../../utils';
import NonTransactionalIntent from '../../../constants/nonTransactionalIntents';
import { IntentHandler } from '../intentHandlerTypes';

const intentHandler: IntentHandler<NonTransactionalIntent.SEE_ORDERS> = {
  handleBrokerPlatform: async ({ broker }) => {
    const brokerConfig = fetchBrokerConfig(broker);
    const transactionOpenMode = getTransactionOpenMode(
      brokerConfig,
      store.thirdPartyCookieSupported,
    );

    const intentURL = brokerConfig.getIntentURL({
      broker,
      openMode: transactionOpenMode,
      gateway: store.gateway,
      intent: NonTransactionalIntent.SEE_ORDERS,
    });
    await WindowManager.closeSecondary();
    const secondaryURL = `pre-connect/?broker=${broker.replace(
      LEPRECHAUN_SUFFIX,
      '',
    )}`;
    const completionPromise = addTransactionListeners({
      broker,
    });
    await WindowManager.openIndirect({
      primaryURL: intentURL,
      primaryWindowType: transactionOpenMode,
      secondaryStatus: secondaryURL,
      broker,
    });
    return {
      shouldReturn: false,
      completionPromise,
    };
  },
  async handleCompletion({ message }) {
    await WindowManager.closePrimary();
    await WindowManager.closeAll();
    if (message.data.status === completionStatus.ERRORED) {
      return handleErrorResponse(message);
    }

    return true;
  },
};

export default intentHandler;
