// Transaction intent
enum TransactionIntent {
  CONNECT = 'CONNECT',
  HOLDINGS_IMPORT = 'HOLDINGS_IMPORT',
  TRANSACTION = 'TRANSACTION',
  SUBSCRIPTION = 'SUBSCRIPTION',
  LEAD_GEN = 'LEAD_GEN',
  /** used to import mutual fund holdigns from mfcentral */
  MF_HOLDINGS_IMPORT = 'MF_HOLDINGS_IMPORT',
}

export default TransactionIntent;
