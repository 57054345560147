/* eslint-disable import/no-cycle */
import {
  sendPostMessageToFrame,
  listenMessageEvent,
} from '.';
import PostMessageType from '../constants/postMessageTypes';

export const enum WindowType {
  NEWTAB = 'newtab',
  IFRAME = 'iframe'
}

export const enum ChangeTarget {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}
// eslint-disable-next-line import/prefer-default-export
class WindowManager {
  static elementOrSelector: string | HTMLElement = 'body';

  static async openIndirect({
    primaryWindowType = undefined,
    primaryURL,
    secondaryStatus = undefined,
    broker = undefined,
    changeOnly = undefined,
    bypassClickToContinue = undefined,
    intent,
    intentData,
    recentBrokers,
    isReturningUser,
  }: {
    primaryWindowType?: WindowType;
    primaryURL?: string;
    secondaryStatus?: string;
    broker?: string;
    changeOnly?: ChangeTarget;
    bypassClickToContinue?: boolean;
    intent?: string;
    intentData?: any;
    recentBrokers?: string[];
    isReturningUser?: boolean;
  }) {
    sendPostMessageToFrame({
      messageType: PostMessageType.MANAGE_WINDOW,
      messageParams: {
        argument: {
          primaryWindowType,
          primaryURL,
          secondaryStatus,
          broker,
          changeOnly,
          bypassClickToContinue,
          intent,
          intentData,
          recentBrokers,
          isReturningUser,
        },
      },
    });

    const {
      data: returnMessageData,
    } = await listenMessageEvent(PostMessageType.MANAGE_WINDOW_FINISHED).promise;

    delete returnMessageData.type;
    return returnMessageData;
  }

  static async closeAll() {
    sendPostMessageToFrame({
      messageType: PostMessageType.CLOSE_ALL_WINDOW,
      messageParams: {},
    });

    const {
      data: returnMessageData,
    } = await listenMessageEvent(PostMessageType.CLOSE_ALL_WINDOW_FINISHED).promise;

    delete returnMessageData.type;
    return returnMessageData;
  }

  static async closePrimary() {
    return WindowManager.openIndirect({
      primaryURL: undefined,
      changeOnly: ChangeTarget.PRIMARY,
    });
  }

  static async closeSecondary() {
    return WindowManager.openIndirect({
      secondaryURL: undefined,
      changeOnly: ChangeTarget.SECONDARY,
    });
  }
}

export default WindowManager;
