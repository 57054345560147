export const lasPostMessageTypes = {
  COMPLETE: 'COMPLETE',
  USER_CANCELLED: 'USER_CANCELLED',
  ASK_APP_ORIGIN: 'ASK_APP_ORIGIN',
  GIVE_APP_ORIGIN: 'GIVE_APP_ORIGIN',
} as const;

export type LasPostMessageType =
  typeof lasPostMessageTypes[keyof typeof lasPostMessageTypes];

export const interactionIntents = {
  LOAN_APPLICATION: 'loanApplication',
  PAYMENT: 'PAYMENT',
} as const;

export type InteractionIntent =
  typeof interactionIntents[keyof typeof interactionIntents];
