const enum PostMessageType {
  SHOW_FRAME = 'SHOW_FRAME',
  HIDE_FRAME = 'HIDE_FRAME',
  GATEWAY_LOGIN = 'GATEWAY_LOGIN',
  LOGIN_BUTTON_CLICKED = 'LOGIN_BUTTON_CLICKED',
  MANAGE_WINDOW = 'MANAGE_WINDOW',
  MANAGE_WINDOW_FINISHED = 'MANAGE_WINDOW_FINISHED',
  CLOSE_ALL_WINDOW = 'CLOSE_ALL_WINDOW',
  CLOSE_ALL_WINDOW_FINISHED = 'CLOSE_ALL_WINDOW_FINISHED',
  CLOSED_WINDOW = 'CLOSED_WINDOW',
  ORDERFLOW_STATUS = 'ORDERFLOW_STATUS',
  ORDERFLOW_COMPLETED = 'ORDERFLOW_COMPLETED',
  LOGIN_REQUIRED = 'LOGIN_REQUIRED',
  USER_CANCELLED = 'USER_CANCELLED',
  ERROR_IN_ORDER = 'ERROR_IN_ORDER',
  RESTART_ORDER = 'RESTART_ORDER',
  CONNECT_LOADED = 'CONNECT_LOADED',
  TRANSACTION_COMPLETE = 'TRANSACTION_COMPLETE',
  TRANSACTION_EXPIRED = 'TRANSACTION_EXPIRED',
  USER_SIGNUP = 'USER_SIGNUP',
  BROKER_TWEET = 'BROKER_TWEET',
  LOGIN_BUTTON_CLICKED_CONSENT_REQUIRED = 'LOGIN_BUTTON_CLICKED_CONSENT_REQUIRED',
  USER_SIGNUP_CLOSE = 'USER_SIGNUP_CLOSE',
  UPDATE_GATEWAY_NAME = 'UPDATE_GATEWAY_NAME',
  /* smallplug post message types */
  SMALLPLUG_INITIALIZE = 'SMALLPLUG_INITIALIZE',
  SMALLPLUG_INITIALIZE_RESPONSE = 'SMALLPLUG_INITIALIZE_RESPONSE',
  SMALLPLUG_HANDLE_INTENT = 'SMALLPLUG_HANDLE_INTENT',
  SMALLPLUG_HANDLE_INTENT_RESPONSE = 'SMALLPLUG_HANDLE_INTENT_RESPONSE',
  SMALLPLUG_HIDE_FRAME = 'SMALLPLUG_HIDE_FRAME',
}

export default PostMessageType;
