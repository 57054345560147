class ScLoanError extends Error {
  code?: string;

  data?: any;

  constructor(message: string, code = null, data?: any) {
    super(message);
    this.name = 'ScLoanError';
    this.code = code;
    if (data) this.data = data;
  }
}

export const lasErrorCodeMap = {
  INTERNAL_ERROR: {
    code: 2000,
    message: 'internal_error',
  },
  USER_CANCELLED: {
    code: 1012,
    message: 'user_cancelled',
  },
  DASHBOARD_ALREADY_OPEN: {
    code: 4009,
    message: 'dashboard_already_open',
  },
};

export const lasErrorInstanceMap = Object.fromEntries(
  Object.entries(lasErrorCodeMap).map(([key, errorObj]) => [
    key,
    new ScLoanError(errorObj.message, errorObj.code),
  ]),
) as Record<keyof typeof lasErrorCodeMap, ScLoanError>;

export default ScLoanError;
