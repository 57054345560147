import { store } from '../../../utils/userStore';
import { ConnectStatus, LEPRECHAUN_SUFFIX } from '../../../constants';
import WindowManager from '../../../utils/windowManager';
import PostMessageType from '../../../constants/postMessageTypes';
import transactionStatus, {
  completionStatus,
} from '../../../constants/transactionStatus';
import errorMap from '../../../utils/errorMap';
import {
  addTransactionListeners,
  fetchTransactionStatus,
  handleErrorResponse,
  handleUserCancelledErrors,
  throwError,
} from '../transactionHelpers';
import {
  fetchBrokerConfig,
  to,
  isFirstParty,
  showStatus,
  statusTimeoutSec,
  getTransactionOpenMode,
} from '../../../utils';
import { IntentHandler } from '../intentHandlerTypes';

const intentHandler: Partial<IntentHandler> = {
  handleBrokerPlatform: async ({ transaction, broker }) => {
    if (store.status === ConnectStatus.CONNECTED) {
      return {
        shouldReturn: true,
        returnObj: {
          smallcaseAuthToken: store.smallcaseAuthToken,
        },
      };
    }
    const brokerConfig = fetchBrokerConfig(broker);
    const transactionOpenMode = getTransactionOpenMode(
      brokerConfig,
      store.thirdPartyCookieSupported,
    );

    const transactionURL = brokerConfig.getIntentURL({
      transactionId: transaction.transactionId,
      broker,
      openMode: transactionOpenMode,
      gateway: store.gateway,
      intent: transaction.intent,
    });
    await WindowManager.closeSecondary();
    const secondaryURL = `pre-connect/?broker=${broker.replace(
      LEPRECHAUN_SUFFIX,
      '',
    )}`;
    const completionPromise = addTransactionListeners({
      broker,
      transactionId: transaction.transactionId,
    });
    await WindowManager.openIndirect({
      primaryURL: transactionURL,
      primaryWindowType: transactionOpenMode,
      secondaryStatus: secondaryURL,
      broker,
      intent: transaction.intent,
    });
    return {
      shouldReturn: false,
      completionPromise,
    };
  },
  async handleCompletion({ transactionId, message, broker }) {
    const [fetchStatusError, statusResponse] = await to(
      fetchTransactionStatus(transactionId),
    );

    if (fetchStatusError) {
      await throwError({
        markTransaction: false,
        error: errorMap.API_ERROR,
      });
    }

    const { transaction } = statusResponse;
    const userCancelled = [
      PostMessageType.USER_CANCELLED,
      PostMessageType.CLOSED_WINDOW,
    ].includes(message.data.type);

    if (
      [transactionStatus.PROCESSING, transactionStatus.COMPLETED].includes(
        transaction.status,
      )
    ) {
      await WindowManager.closePrimary();
      if (!isFirstParty(store.gateway)) {
        await showStatus({
          secondaryStatus: `post-connect/?broker=${broker}&t=${statusTimeoutSec}&distributor=${store.displayName}`,
        });
      }
      await WindowManager.closeAll();
    } else if (userCancelled) {
      await handleUserCancelledErrors(transaction);
    }

    if (message.data.status === completionStatus.ERRORED) {
      return handleErrorResponse(message, transaction);
    }

    return {
      transactionId: transaction.transactionId,
      smallcaseAuthToken: transaction.success.smallcaseAuthToken,
      ...(typeof transaction.success.signup === 'boolean' && {
        signup: transaction.success.signup,
      }),
      broker: transaction.success?.broker,
    };
  },
};

export default intentHandler;
