import { getStorageItem, setStorageItem } from './storage';
import { ConnectStatus } from '../constants';
import { ExitedSmallcase, InvestedSmallcase } from '../types/smallcase';

type Store = {
  token?: string;
  CSRF?: string;
  smallcaseAuthToken?: string;
  broker?: string;
  brokerId?: any;
  gateway?: string;
  gatewayId?: any;
  status?: ConnectStatus;
  smallcaseId?: string;
  displayName?: string;
  defaultSCName?: string;
  investments?: InvestedSmallcase[];
  exited?: ExitedSmallcase[];
  amo?: boolean;
  thirdPartyCookieSupported: boolean;
  featureBrokerList: { [feature: string]: string[] };
  addRecentBroker(broker: string): void;
  readonly recentBrokers: string[];
  addRecentIntents(intent: string): void;
  readonly recentIntents: { [intent: string]: number }; // TODO refactor this for proper typing
  deviceType?: string;
  loanGateway?: string;
};
/* eslint-disable import/prefer-default-export */
export const store = {
  _token: undefined,
  _csrf: undefined,
  _smallcaseAuthToken: undefined,
  _broker: undefined,
  _brokerId: undefined,
  _gateway: undefined,
  _gatewayId: undefined,
  _status: undefined,
  _smallcaseId: undefined,
  _displayName: undefined,
  _defaultSCName: undefined,
  _investments: [],
  _exited: [],
  _amo: false,
  _thirdPartyCookieSupported: false,
  _featureBrokerList: undefined,
  _recentBrokers: undefined,
  _recentIntents: undefined,
  _loanGateway: undefined,

  set token(str) {
    this._token = str;
    setStorageItem('token', str, 'session');
  },
  get token() {
    return this._token || getStorageItem('token', 'session');
  },

  addRecentBroker(broker: string) {
    // Fetch from local storage
    const recentBrokers = JSON.parse(
      getStorageItem('recentBrokers', 'local') || '[]',
    );

    // use non-leprechaun broker name
    const brokerName = broker.split('-leprechaun')[0];

    // If already present, remove from the list
    const indexOfItem = recentBrokers.indexOf(brokerName);
    if (indexOfItem > -1) {
      recentBrokers.splice(indexOfItem, 1);
    }

    // Add this broker to the front
    recentBrokers.unshift(brokerName);

    // Keep only 8 brokers at max
    if (recentBrokers.length > 8) {
      recentBrokers.splice(8, recentBrokers.length - 8);
    }

    // Save back to userStore and localstorage
    this._recentBrokers = recentBrokers;
    setStorageItem('recentBrokers', JSON.stringify(recentBrokers), 'local');
  },

  get recentBrokers() {
    return (
      this._recentBrokers ||
      JSON.parse(getStorageItem('recentBrokers', 'local') || '[]')
    );
  },

  addRecentIntents(intent: string) {
    // Fetch from local storage
    const recentIntents = JSON.parse(
      getStorageItem('recentIntents', 'local') || '{}',
    );

    // if intent is not already in the object, initiate it
    if (!recentIntents[intent]) {
      recentIntents[intent] = 0;
    }
    // increment counter by one
    recentIntents[intent] += 1;

    // Save back to userStore and localstorage
    this._recentIntents = recentIntents;
    setStorageItem('recentIntents', JSON.stringify(recentIntents), 'local');
  },

  get recentIntents() {
    return (
      this._recentIntents ||
      JSON.parse(getStorageItem('recentIntents', 'local') || '{}')
    );
  },

  set CSRF(str) {
    this._csrf = str;
  },
  get CSRF() {
    return this._csrf;
  },

  set smallcaseAuthToken(str) {
    this._smallcaseAuthToken = str;
  },
  get smallcaseAuthToken() {
    return this._smallcaseAuthToken;
  },

  set broker(str) {
    this._broker = str;
  },
  get broker() {
    return this._broker;
  },

  set brokerId(str) {
    this._brokerId = str;
  },
  get brokerId() {
    return this._brokerId;
  },

  set gateway(str) {
    this._gateway = str;
  },
  get gateway() {
    return this._gateway;
  },

  set gatewayId(str) {
    this._gatewayId = str;
  },
  get gatewayId() {
    return this._gatewayId;
  },

  set smallcaseId(str) {
    this._smallcaseId = str;
  },
  get smallcaseId() {
    return this._smallcaseId;
  },

  set status(str) {
    this._status = str;
  },
  get status() {
    return this._status;
  },

  set displayName(str) {
    this._displayName = str;
  },
  get displayName() {
    return this._displayName;
  },

  set defaultSCName(str) {
    this._defaultSCName = str;
  },
  get defaultSCName() {
    return this._defaultSCName;
  },

  // @ts-ignore
  set investments(arr = []) {
    this._investments = [...arr];
  },
  get investments() {
    return this._investments;
  },

  // @ts-ignore
  set exited(arr = []) {
    this._exited = [...arr];
  },
  get exited() {
    return this._exited;
  },

  set amo(bool) {
    this._amo = bool;
  },
  get amo() {
    return this._amo;
  },

  set thirdPartyCookieSupported(value) {
    this._thirdPartyCookieSupported = value;
  },
  get thirdPartyCookieSupported() {
    return this._thirdPartyCookieSupported;
  },

  set featureBrokerList(value) {
    this._featureBrokerList = value;
  },
  get featureBrokerList() {
    return this._featureBrokerList;
  },

  set loanGateway(str) {
    this._loanGateway = str;
  },
  get loanGateway() {
    return this._loanGateway;
  },
} as Store;
