import errorMap from '../../utils/errorMap';
import httpHandler, { RequestType } from '../../services/apiService';
import apiMap from '../../utils/apiMap';
import { ChartResponseType } from '../../types/apiResponses/chart';

const getHistorical = function historical({
  scid,
  duration,
  base = '100',
  benchmarkId,
  benchmarkType = 'index',
}: {
  scid?: string; // TODO: make scid mandatory
  duration?: string;
  base?: string;
  benchmarkId?: string;
  benchmarkType?: string;
} = {}) {
  if (!scid) {
    throw errorMap.INVALID_SCID;
  }
  return httpHandler<ChartResponseType>(RequestType.GET, apiMap.CHART, {
    scid,
    duration,
    base,
    benchmarkId,
    benchmarkType,
  }).then((res) => res.data);
};

export default getHistorical;
