import type scDK from '../../sdk/main';
import { Transaction } from '../../types/transaction';
import getIntentHandler from './intentHandler';
import getBroker from './utils/getBroker';
import handleLoginRequired from './utils/handleLoginRequired';

async function executeBrokerTransaction(
  this: scDK,
  transaction: Transaction,
  brokers: string[],
  leprechaun: boolean,
) {
  const th = getIntentHandler(transaction.intent);
  const broker = await getBroker.call(this, {
    brokers,
    leprechaun,
    transactionId: transaction.transactionId,
    intent: transaction.intent,
    orderConfig: transaction.orderConfig,
    txnConfig: transaction.config,
    transaction,
  });

  const { shouldReturn, completionPromise, returnObj } =
    await th.handleBrokerPlatform({
      transaction,
      broker,
    });

  if (shouldReturn) {
    return returnObj;
  }

  const message = await completionPromise;
  const { restartTransaction } = await handleLoginRequired.call(this, {
    message,
    transaction,
    broker,
  });
  if (restartTransaction) {
    return this.triggerTransaction.call(this, {
      brokers,
      leprechaun,
      transactionId: transaction.transactionId,
    });
  }
  return th.handleCompletion({
    message,
    transactionId: transaction.transactionId,
    broker,
  });
}

export default executeBrokerTransaction;
