import {
  InteractionDetails,
  InteractionDetailsResponse,
  UnityErrorResponse,
} from '../types/apiResponses/unityResponses';
import { to } from '../utils';
import {
  closeIframe,
  ensureConnectFrameInjected,
  getGwClientIframe,
  openUrlInIframe,
} from '../utils/iframe';
import WindowManager, { ChangeTarget } from '../utils/windowManager';
import { fetchInteractionDetails, fetchInteractionStatus } from './api';
import { listenForClientMessage } from './utils';
import createBrowserHistoryHelper from './utils/browserHistory';
import ScLoanError, { lasErrorInstanceMap } from './utils/scLoanError';

/**
 * This class contains template/common functionality for las methods,
 * las methods are free to override any functionality by extending the class
 * however the execution of these method are done using the lasRunner util method
 *
 */
class BaseLasStrategy {
  protected interactionToken: string;

  protected interactionDetails: InteractionDetails;

  /** configurable params that controls the flow */
  // can be overriden in constructors
  protected interactionParams = {
    connectFramePath: 'las-loader',
  };

  constructor(interactionToken: string) {
    this.interactionToken = interactionToken;
  }

  async initInteraction() {
    await ensureConnectFrameInjected();
    // show loading screen while api resolves
    WindowManager.openIndirect({
      changeOnly: ChangeTarget.SECONDARY,
      secondaryStatus: this.interactionParams.connectFramePath,
    });

    const [interactionDetailsError, interactionDetailsResponse] = await to<
      InteractionDetailsResponse,
      UnityErrorResponse
    >(fetchInteractionDetails(this.interactionToken));
    if (interactionDetailsError)
      throw new ScLoanError(
        interactionDetailsError.response.data.message,
        interactionDetailsError.response.data.code,
      );
    this.interactionDetails = interactionDetailsResponse.data;
  }

  async runInteraction() {
    const resetHistory = createBrowserHistoryHelper();

    // setup for listeners to resolve on postmessage from las_client
    const listenerPromise = listenForClientMessage(this.interactionDetails.url);

    // open las_client
    const iframeEl = getGwClientIframe();
    await openUrlInIframe(iframeEl, this.interactionDetails.url);

    // close loader
    await WindowManager.closeSecondary();

    // disable scroll
    document.body.style.overflow = 'hidden';

    // wait for listeners to get some message from client
    const clientMesssage = await listenerPromise;

    // remove transaction ui as soon as possible
    closeIframe(iframeEl);

    resetHistory();

    // enable scroll
    document.body.style.overflow = '';

    const clientPayload = clientMesssage.data.payload;
    if (clientPayload.code !== 0) {
      throw new ScLoanError(clientPayload.message, clientPayload.code);
    }
  }

  async finishInteraction() {
    // show loading screen while api resolves
    WindowManager.openIndirect({
      changeOnly: ChangeTarget.SECONDARY,
      secondaryStatus: 'smallcase-loader',
    });

    // get latest interaction status
    const [apiError, statusResponse] = await to(
      fetchInteractionStatus(this.interactionToken),
    );

    // close loader once api resolves
    WindowManager.closeSecondary();

    if (apiError) {
      throw lasErrorInstanceMap.INTERNAL_ERROR;
    }
    if (statusResponse.code !== 0)
      throw new ScLoanError(statusResponse.message, statusResponse.code);

    return statusResponse.data;
  }

  async handleInteractionError(err: Error) {
    const { code, message } =
      err instanceof ScLoanError ? err : lasErrorInstanceMap.INTERNAL_ERROR;
    const [apiError, statusResponse] = await to(
      fetchInteractionStatus(this.interactionToken, { code, message }),
    );

    WindowManager.closeAll();

    if (apiError) {
      throw lasErrorInstanceMap.INTERNAL_ERROR;
    }
    if (statusResponse.code !== 0)
      throw new ScLoanError(
        statusResponse.message,
        statusResponse.code,
        statusResponse.data,
      );

    return statusResponse.data;
  }
}

export default BaseLasStrategy;
