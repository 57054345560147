import { GatewayError } from './customErrorTypes';

const errorMap = {
  /* could not find HTMLElement to insert Iframe */
  INVALID_ELEMENTORSELECTOR: new GatewayError('invalid_elementorselector'),

  /* gateway_name passed in the sdk initialization is invalid or not registered */
  INVALID_GATEWAY: new GatewayError('invalid_gateway'),

  /* smallcaseAuthToken is not provided or is invalid */
  INVALID_USER: new GatewayError('invalid_user_data'),

  INIT_FAILED: new GatewayError('invalid_user_session'),

  /* a smallcase could not be found with given information */
  INVALID_SCID: new GatewayError('invalid_scid'),

  /* cannot find the invested smallcase */
  INVALID_ISCID: new GatewayError('invalid_iscid'),

  /* transactionId passed to the sdk is incorrect */
  INVALID_TRANSACTION: new GatewayError('invalid_transaction'),

  NOT_AUTHORIZED: new GatewayError('not_authorized'),

  NOT_CONNECTED: new GatewayError('user_not_connected'),

  NOT_INVESTED: new GatewayError('not_invested'),

  INVALID_USER_SESSION: new GatewayError('invalid_user_session'),

  /* transaction session expired */
  TIMED_OUT: new GatewayError('timed_out', 4003),

  /* something went wrong while trying to place the order */
  INVALID_ORDER: new GatewayError('invalid_order', 3002),

  /* user has closed the broker chooser popup */
  USER_CANCELLED: new GatewayError('user_cancelled', 1010),

  /** user is expected to login with the mapped broker account only.
   * user logged in with another broker account */
  USER_MISMATCH: new GatewayError('user_mismatch', 1001),

  /* broker is not accepting orders */
  MARKET_CLOSED: new GatewayError('market_closed', 4004),

  /* user wants to sell a stock he does not own, order can not be placed */
  INSUFFICIENT_HOLDINGS: new GatewayError('insufficent_holdings', 1004),

  /* something went wrong with our APIs/SDK */
  API_ERROR: new GatewayError('internal_error', 2000),

  ERROR: new GatewayError('api_error'),

  ORDER_IN_QUEUE: new GatewayError('order_in_queue'),

  /* user denied consent for holdings import */
  CONSENT_DENIED: new GatewayError('consent_denied', 1003),

  /* transaction session expired */
  TRANSACTION_EXPIRED: new GatewayError('transaction_expired', 1005),

  /* user could not find his broker in the available list */
  NO_BROKER: new GatewayError('no_broker', 1006),

  /** user could not find his broker in the available list
   * & opted to signup for a new broking account */
  NO_BROKER_SIGNUP: new GatewayError('no_broker', 1007),

  /* selected brokers do not support the transaction intent */
  NO_BROKER_SUPPORT: new GatewayError('no_broker', 1008),

  /* rebalance was ignored */
  NO_ORDER: new GatewayError('no_order', 1020),

  /* no shortfall detected while reconciliation */
  NO_SHORTFALL: new GatewayError('no_shortfall', 1021),

  SIGNUP_CLICK: new Error('signup_click'),

  /* last order by the user is still pending */
  ORDER_PENDING: new GatewayError('order_pending', 4006),

  /* gatewayPostbackUrl could not be reached */
  WEBHOOK_UNREACHABLE: new GatewayError('webhook_unreachable', 3003),

  /* user has already subscribed */
  ALREADY_SUBSCRIBED: new GatewayError('already_subscribed', 4000),

  /* user has closed the order journey after selecting the broker, but before successful login */
  USER_INIT_THEN_CANCEL: new GatewayError('user_cancelled', 1011),

  /* user completed broker login to view the order popup but abandoned the order journey */
  USER_LOGIN_THEN_CANCEL: new GatewayError('user_cancelled', 1012),

  /* transactionId passed in the triggerTransaction method is used or expired */
  TRANSACTION_USED: new GatewayError('transaction_expired', 3001),

  /* amo orders are not accepted by the broker */
  AMO_CLOSED: new GatewayError('market_closed', 4005),

  /* smallcase has been deleted post archival */
  SMALLCASE_ARCHIVED: new GatewayError('smallcase_archived', 1013),

  /* its not possible to trade over NSE using the users account */
  TRADING_NOT_ENABLED: new GatewayError('trading_not_enabled', 1030),
};
export type SdkErrorStruct = {
  code: number;
  message: string;
  error: GatewayError;
};

export const apiErrorCodeMap: { [key: number]: SdkErrorStruct } = {
  1001: {
    code: 1001,
    message: 'user_mismatch',
    error: errorMap.USER_MISMATCH,
  },
  1003: {
    code: 1003,
    message: 'consent_denied',
    error: errorMap.CONSENT_DENIED,
  },
  1004: {
    code: 1004,
    message: 'insufficient_holdings',
    error: errorMap.INSUFFICIENT_HOLDINGS,
  },
  1005: {
    code: 1005,
    message: 'transaction_expired',
    error: errorMap.TRANSACTION_EXPIRED,
  },
  1006: {
    code: 1006,
    message: 'no_broker',
    error: errorMap.NO_BROKER,
  },
  1007: {
    code: 1007,
    message: 'no_broker',
    error: errorMap.NO_BROKER_SIGNUP,
  },
  1008: {
    code: 1008,
    message: 'no_broker',
    error: errorMap.NO_BROKER_SUPPORT,
  },
  1010: {
    code: 1010,
    message: 'user_cancelled',
    error: errorMap.USER_CANCELLED,
  },
  1011: {
    code: 1011,
    message: 'user_cancelled',
    error: errorMap.USER_INIT_THEN_CANCEL,
  },
  1012: {
    code: 1012,
    message: 'user_cancelled',
    error: errorMap.USER_LOGIN_THEN_CANCEL,
  },
  1013: {
    code: 1013,
    message: 'smallcase_archived',
    error: errorMap.SMALLCASE_ARCHIVED,
  },
  1020: {
    code: 1020,
    message: 'no_order',
    error: errorMap.NO_ORDER,
  },
  1021: {
    code: 1021,
    message: 'no_shortfall',
    error: errorMap.NO_SHORTFALL,
  },
  1030: {
    code: 1030,
    message: 'trading_not_enabled',
    error: errorMap.TRADING_NOT_ENABLED,
  },
  2000: {
    code: 2000,
    message: 'internal_error',
    error: errorMap.API_ERROR,
  },
  3001: {
    code: 3001,
    message: 'transaction_expired',
    error: errorMap.TRANSACTION_USED,
  },
  3002: {
    code: 3002,
    message: 'invalid_order',
    error: errorMap.INVALID_ORDER,
  },
  3003: {
    code: 3003,
    message: 'webhook_unreachable',
    error: errorMap.WEBHOOK_UNREACHABLE,
  },
  4000: {
    code: 4000,
    message: 'already_subscribed',
    error: errorMap.ALREADY_SUBSCRIBED,
  },
  4003: {
    code: 4003,
    message: 'timed_out',
    error: errorMap.TIMED_OUT,
  },
  4004: {
    code: 4004,
    message: 'market_closed',
    error: errorMap.MARKET_CLOSED,
  },
  4005: {
    code: 4005,
    message: 'market_closed',
    error: errorMap.AMO_CLOSED,
  },
  4006: {
    code: 4006,
    message: 'order_pending',
    error: errorMap.ORDER_PENDING,
  },
};

export const getErrorObj = (
  code?: number,
  message?: string,
): SdkErrorStruct => {
  if (!code || !message) return apiErrorCodeMap[2000];
  if (apiErrorCodeMap[code]) return apiErrorCodeMap[code];
  return {
    code,
    message,
    error: new GatewayError(message, code),
  };
};
export default errorMap;
