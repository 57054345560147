const createBrowserHistoryHelper = () => {
  // purge all forward history and get correct length
  window.history.pushState(null, document.title, window.location.href);
  window.history.back();
  const startingLength = window.history.length;
  return function restoreHistory() {
    const lengthBeforePush = window.history.length;
    // purge forward history and get current length
    window.history.pushState(null, document.title, window.location.href);
    const currentLength = window.history.length;
    // negative integer indicating the no of history entries we have to travel
    let lengthToPop = startingLength - currentLength - 1;
    if (currentLength - lengthBeforePush > 1) {
      // firefox removes all the iFrame history entries
      lengthToPop = -1;
    }
    // go back in history
    window.history.go(lengthToPop);
  };
};

export default createBrowserHistoryHelper;
