import transactionStatus from '../constants/transactionStatus';
import {
  getAuthDataFromTransaction,
  throwError,
} from '../methods/transaction/transactionHelpers';
import { Transaction } from '../types/transaction';
import errorMap, { apiErrorCodeMap } from './errorMap';

/** check if the transaction state indicates success and throw if not */
export const verifyTransactionStatus = async (transaction: Transaction) => {
  if (transaction.status !== transactionStatus.INITIALIZED) {
    await throwError({
      markTransaction: false,
      error: errorMap.TRANSACTION_EXPIRED,
      data: getAuthDataFromTransaction(transaction),
    });
  } else if (transaction.expired) {
    await throwError({
      markTransaction: true,
      transactionId: transaction.transactionId,
      errorObj: apiErrorCodeMap[3001],
      data: getAuthDataFromTransaction(transaction),
    });
  }
};

/** if the transaction status api failed, throw appropriate error */
export const handleTransactionApiErrors = async (apiErrorResponse: Error) => {
  if (!apiErrorResponse) return;
  // @ts-ignore // TODO type this correctly
  if (apiErrorResponse.res?.errorType === 'InputException') {
    await throwError({
      markTransaction: false,
      error: errorMap.INVALID_TRANSACTION,
    });
  }
  await throwError({
    markTransaction: false,
    error: errorMap.API_ERROR,
  });
};
