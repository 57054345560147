import qs from 'query-string';
import axios from 'axios';
import * as stacktraceParser from 'stacktrace-parser';
import { store } from '../utils/userStore';
import uuid4 from '../utils/uuid';
import {
  SENTRY_HOST,
  SENTRY_PROJECT,
  SENTRY_VERSION,
  SENTRY_CLIENT,
  SENTRY_KEY,
} from '../constants/sentry';
import {
  disableBackButton, waitForBackEnable, isBackDisabled,
} from '../utils/backButton';

const sessionStart = Date.now();

function formatStackFrame({
  lineNumber,
  file,
  column,
  methodName,
}: stacktraceParser.StackFrame) {
  return {
    'filename': file,
    'lineno': lineNumber,
    'colno': column,
    'function': methodName,
    'in_app': true,
  };
}

function parseException(exception: Error) {
  if (!exception.stack) {
    return {
      type: 'error',
      value: exception,
    };
  }

  const stack = stacktraceParser.parse(exception.stack);
  return {
    type: exception.name,
    value: exception.message,
    stacktrace: {
      frames: stack.map(formatStackFrame),
    },
  };
}

function getSentryClient({
  sentryHost = SENTRY_HOST,
  project = SENTRY_PROJECT,
  sentryVersion = SENTRY_VERSION,
  sentryClient = SENTRY_CLIENT,
  sentryKey = SENTRY_KEY,
  protocol = 'https',
} = {}) {
  const qstr = qs.stringify({
    sentry_version: sentryVersion,
    sentry_client: sentryClient,
    sentry_key: sentryKey,
  });

  const reqUrl = `${protocol}://${sentryHost}/api/${project}/store/?${qstr}`;
  const makePayload = (exception: Error, type = '', handled = false) => ({
    project,
    logger: 'javascript',
    platform: 'javascript',
    request: {
      headers: {
        'User-Agent': navigator.userAgent,
        'Referer': document.referrer,
      },
      url: document.referrer,
    },
    exception: {
      values: [parseException(exception)],
      mechanism: {
        type,
        handled,
      },
    },
    // TODO: Add transaction info
    // transaction,
    tags: {
      component: 'scdk',
      gateway: store.gateway,
    },
    extra: {
      'session:duration': Date.now() - sessionStart,
    },
    release: (window.SCDK_RELEASE && window.SCDK_RELEASE.id),
    event_id: uuid4(),
  });

  return (exception: Error, type = '', handled = false) => {
    BUILD_WITH_SENTRY && axios.post(reqUrl, makePayload(exception, type, handled)).then(() => 1);
  };
}

const sendToSentry = getSentryClient({});

function guardedCall<T extends any[], U>(
  fn: (...args:T) => Promise<U>,
  handleBackButton: boolean = false,
) {
  return async function inner(...args: T):Promise<U> {
    try {
      if (handleBackButton) {
        const functionIdentifier = uuid4();
        disableBackButton(functionIdentifier);
        try {
          // Get the response but wait for back button to be enabled
          const response = await fn.apply(this, args);
          if (isBackDisabled()) {
            await waitForBackEnable(functionIdentifier);
          }
          return response;
        } catch (err) {
          await waitForBackEnable(functionIdentifier);
          throw err;
        }
      }
      return fn.apply(this, args);
    } catch (e) {
      try {
        sendToSentry(e);
      } catch (e1) {
        console.error(e1);
      }
      throw e;
    }
  };
}

export default guardedCall;
