import WindowManager, { ChangeTarget } from '../../../utils/windowManager';
import { store } from '../../../utils/userStore';
import { LEPRECHAUN_SUFFIX } from '../../../constants';
import PostMessageType from '../../../constants/postMessageTypes';
import transactionStatus, {
  completionStatus,
} from '../../../constants/transactionStatus';
import errorMap from '../../../utils/errorMap';
import { fetchBrokerConfig, to, getTransactionOpenMode } from '../../../utils';
import {
  throwError,
  fetchTransactionStatus,
  handleErrorResponse,
  addTransactionListeners,
  handleUserCancelledErrors,
} from '../transactionHelpers';
import { IntentHandler } from '../intentHandlerTypes';

const intentHandler: IntentHandler = {
  handleBrokerPlatform: async ({ transaction, broker }) => {
    await WindowManager.openIndirect({
      changeOnly: ChangeTarget.SECONDARY,
      secondaryStatus: 'smallcase-loader',
    });
    const brokerConfig = fetchBrokerConfig(broker);
    const transactionOpenMode = getTransactionOpenMode(
      brokerConfig,
      store.thirdPartyCookieSupported,
    );
    const transactionURL = brokerConfig.getIntentURL({
      transactionId: transaction.transactionId,
      broker,
      openMode: transactionOpenMode,
      gateway: store.gateway,
      intent: transaction.intent,
    });
    await WindowManager.closeSecondary();
    // config key is for SUBSCRIPTION intent
    const smallcaseName = transaction.config.name;
    const secondaryURL = `orderflow-waiting/?broker=${broker.replace(
      LEPRECHAUN_SUFFIX,
      '',
    )}&smallcase=${smallcaseName}`;
    const completionPromise = addTransactionListeners({
      broker,
      transactionId: transaction.transactionId,
    });
    await WindowManager.openIndirect({
      primaryURL: transactionURL,
      primaryWindowType: transactionOpenMode,
      secondaryStatus: secondaryURL,
      broker,
      intent: transaction.intent,
    });
    return {
      shouldReturn: false,
      completionPromise,
    };
  },
  async handleCompletion({ transactionId, message }) {
    const [fetchStatusError, statusResponse] = await to(
      fetchTransactionStatus(transactionId),
    );

    if (fetchStatusError) {
      await throwError({
        markTransaction: false,
        error: errorMap.API_ERROR,
      });
    }

    const { transaction } = statusResponse;
    const userCancelled = [
      PostMessageType.USER_CANCELLED,
      PostMessageType.CLOSED_WINDOW,
    ].includes(message.data.type);
    let returnObj = {};
    if (transaction.status === transactionStatus.COMPLETED) {
      await WindowManager.closeAll();

      returnObj = {
        notes: transaction.success.notes,
        smallcaseAuthToken: transaction.success.smallcaseAuthToken,
        ...(transaction.success.broker && {
          broker: transaction.success.broker,
        }),
        ...(typeof transaction.success.signup === 'boolean' && {
          signup: transaction.success.signup,
        }),
        transactionId: transaction.transactionId,
      };
      Object.assign(returnObj, transaction.success.data);
    } else if (userCancelled) {
      await handleUserCancelledErrors(transaction);
    }

    if (message.data.status === completionStatus.ERRORED) {
      return handleErrorResponse(message, transaction);
    }

    return returnObj;
  },
};

export default intentHandler;
