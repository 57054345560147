import { store } from '../../../utils/userStore';
import WindowManager, { ChangeTarget } from '../../../utils/windowManager';
import { LEPRECHAUN_SUFFIX } from '../../../constants';
import PostMessageType from '../../../constants/postMessageTypes';
import transactionStatus, {
  completionStatus,
} from '../../../constants/transactionStatus';
import errorMap from '../../../utils/errorMap';
import {
  fetchBrokerConfig,
  to,
  getTransactionOpenMode,
  wait,
} from '../../../utils';
import {
  throwError,
  addTransactionListeners,
  fetchTransactionStatus,
  handleErrorResponse,
  handleUserCancelledErrors,
} from '../transactionHelpers';
import TransactionIntent from '../../../constants/transactionIntents';
import { IntentHandler } from '../intentHandlerTypes';

type CompleteTransactionReturnObj = {
  notes?: string;
  broker?: string;
  transactionId?: string;
  smallcaseAuthToken?: string;
  orderBatches?: any[];
  sipDetails?: any;
  signup?: boolean;
  actions?: Record<string, string>;
};

const MAX_POLL_COUNT = 15;

const intentHandler: IntentHandler = {
  handleBrokerPlatform: async ({ transaction, broker }) => {
    await WindowManager.openIndirect({
      changeOnly: ChangeTarget.SECONDARY,
      secondaryStatus: 'smallcase-loader',
    });
    const brokerConfig = fetchBrokerConfig(broker);
    const transactionOpenMode = getTransactionOpenMode(
      brokerConfig,
      store.thirdPartyCookieSupported,
    );
    const transactionURL = brokerConfig.getIntentURL({
      transactionId: transaction.transactionId,
      broker,
      openMode: transactionOpenMode,
      gateway: store.gateway,
      intent: transaction.intent,
    });

    if (transaction?.orderConfig?.type === 'RECONCILIATION') {
      // eslint-disable-next-line no-param-reassign
      transaction.orderConfig.name = 'smallcase reconciliation';
    }

    await WindowManager.closeSecondary();
    const secondaryURL = `orderflow-waiting/?broker=${broker.replace(
      LEPRECHAUN_SUFFIX,
      '',
    )}&smallcase=${transaction.orderConfig.name}`;
    const completionPromise = addTransactionListeners({
      broker,
      transactionId: transaction.transactionId,
    });
    await WindowManager.openIndirect({
      primaryURL: transactionURL,
      primaryWindowType: transactionOpenMode,
      secondaryStatus: secondaryURL,
      broker,
      intent: transaction.intent,
    });
    return {
      shouldReturn: false,
      completionPromise,
    };
  },
  async handleCompletion({ transactionId, message }, pollCount = 0) {
    const [fetchStatusError, statusResponse] = await to(
      fetchTransactionStatus<TransactionIntent.TRANSACTION>(transactionId),
    );

    if (fetchStatusError) {
      await throwError({
        markTransaction: false,
        error: errorMap.API_ERROR,
      });
    }
    const { transaction } = statusResponse;
    if (
      transactionStatus.USED === transaction.status &&
      transaction.flags &&
      transaction.flags.isOrderRequested
    ) {
      if (pollCount >= MAX_POLL_COUNT - 1) {
        await throwError({
          markTransaction: true,
          transactionId: transaction.transactionId,
          error: errorMap.ORDER_PENDING,
        });
      }
      // call itself again to get the updated transaction status after waiting for 2 seconds
      await wait(2000);
      return this.handleCompletion({ transaction, message }, pollCount + 1);
    }
    const userCancelled = [
      PostMessageType.USER_CANCELLED,
      PostMessageType.CLOSED_WINDOW,
    ].includes(message.data.type);
    let returnObj: CompleteTransactionReturnObj = {};
    if (
      [
        transactionStatus.PROCESSING,
        transactionStatus.COMPLETED,
        transactionStatus.ACTION_REQUIRED,
      ].includes(transaction.status)
    ) {
      await WindowManager.closeAll();
      returnObj.notes = transaction.success.notes;
      returnObj.smallcaseAuthToken = transaction.success.smallcaseAuthToken;
      returnObj.transactionId = transaction.transactionId;
      returnObj.orderBatches = transaction.success.data.batches;
      delete transaction.success.data.batches;
      // @ts-ignore; userId used to exist in this object. TODO: check and remove
      delete transaction.success.data.userId;
      if (transaction.success.broker) {
        returnObj.broker = transaction.success.broker;
      }
      if (typeof transaction.success.signup === 'boolean') {
        returnObj.signup = transaction.success.signup;
      }
      returnObj = Object.assign(returnObj, transaction.success.data);
    } else if (userCancelled) {
      await handleUserCancelledErrors(transaction);
    }

    if (message.data.status === completionStatus.ERRORED) {
      return handleErrorResponse(message, transaction);
    }

    return returnObj;
  },
};

export default intentHandler;
