import httpHandler, { RequestType } from '../../services/apiService';
import apiMap from '../../utils/apiMap';
import errorMap from '../../utils/errorMap';
import { store } from '../../utils/userStore';
import { isFirstParty, notifyConnectWithGatewayName } from '../../utils';
import { InitSessionResponse } from '../../types/apiResponses/initSession';
import { updateSdkReadinessPromise } from '../../utils/sdkReadiness';

type InitConfig = {
  /**
   * JWT which is signed using a shared secret between smallcase API and gateway backend
   * */
  userData?: string;
  /**
   * JWT which is signed using a shared secret between smallcase API and gateway backend
   * either this or userData must be provided
   * */
  smallcaseAuthToken?: string;
  initHandler?: (
    gatewayToken: string,
    displayName: string,
    status: string,
  ) => void;
};

/**
 *  Starts the session between smallcase API and gateway.
 * @returns {Promise<string>} - A Promise will be returned which will update
 * the value of CSRF token, will resolve with value true
 */
function init({
  userData,
  smallcaseAuthToken,
  initHandler,
}: InitConfig = {}): Promise<boolean> {
  if (!userData && !smallcaseAuthToken) {
    throw errorMap.INVALID_USER;
  }
  const initPromise = httpHandler<InitSessionResponse>(
    RequestType.POST,
    apiMap.SESSION_INIT,
    {
      userData: userData || smallcaseAuthToken,
    },
  )
    .then((res) => {
      if (res.data) {
        store.CSRF = res.data.csrf;
        store.smallcaseAuthToken = res.data.smallcaseAuthToken;
        store.gatewayId = res.data.gatewayUserId;
        store.smallcaseId = res.data.userData?._id;
        store.brokerId =
          res.data.userData &&
          res.data.userData.broker &&
          res.data.userData.broker.userId;
        store.status = res.data.status;
        store.defaultSCName = res.data.defaultSCName;
        store.displayName = res.data.displayName;
        store.featureBrokerList = res.data.allowedBrokers;
        if (store.status === 'CONNECTED') {
          store.investments = res.data.userData?.investedSmallcases || [];
          store.exited = res.data.userData?.exitedSmallcases || [];
          store.broker = res.data.userData?.broker.name;
        } else {
          store.broker = undefined;
          store.investments = [];
          store.exited = [];
        }

        if (isFirstParty(store.gateway) && initHandler) {
          const { gatewayToken, displayName, status } = res.data;
          initHandler(gatewayToken, displayName, status);
        }
        notifyConnectWithGatewayName(res.data.displayName);
      }
      return res.success;
    })
    .catch((err) => {
      if (err.res?.errors.includes('Invalid JWT')) {
        throw errorMap.INVALID_USER;
      }
      throw err;
    });
  updateSdkReadinessPromise('initSessionApi', initPromise);
  return initPromise;
}

export default init;
