/* eslint-disable import/prefer-default-export */
import qs from 'query-string';
import { listenMessageEvent, to, showStatus } from '../../utils';
import { store } from '../../utils/userStore';
import { ConnectStatus, GatewayLogin } from '../../constants';
import PostMessageType from '../../constants/postMessageTypes';
import WindowManager from '../../utils/windowManager';
import errorMap, {
  apiErrorCodeMap,
  getErrorObj,
  SdkErrorStruct,
} from '../../utils/errorMap';
import httpHandler, { RequestType } from '../../services/apiService';
import apiMap from '../../utils/apiMap';
import transactionStatus, {
  userMismatch,
} from '../../constants/transactionStatus';
import {
  OrderConfig,
  Transaction,
  TransactionConfig,
} from '../../types/transaction';
import { TransactionStatusResponse } from '../../types/apiResponses/transactionStatus';
import { UpdateTransactionResponse } from '../../types/apiResponses/updateTransaction';
import { MarketStatusResponse } from '../../types/apiResponses/marketStatus';
import { MarkErroredResponse } from '../../types/apiResponses/markErrored';
import { LeadData, SdkPostMessageData } from '../../types/messages';
import TransactionIntent from '../../constants/transactionIntents';
import NonTransactionalIntent from '../../constants/nonTransactionalIntents';
import { GatewayError } from '../../utils/customErrorTypes';
import signup from '../signup';

// Mark transaction status
function markTransactionStatus({
  transactionId,
  status,
  errorMessage,
  errorCode,
}: {
  transactionId: string;
  status: string;
  errorMessage: string;
  errorCode: number;
}) {
  if (!transactionId) {
    return Promise.resolve();
  }
  return httpHandler<MarkErroredResponse>(
    RequestType.POST,
    apiMap.MARK_TRANSACTION,
    {
      transactionId,
      status,
      errorMessage,
      errorCode,
    },
  );
}

export async function updateTransaction({
  broker,
  transactionId,
  agent,
  consent,
}: {
  broker?: string;
  transactionId: string;
  agent?: string;
  consent?: boolean;
}) {
  if (!transactionId) {
    return Promise.resolve();
  }
  return httpHandler<UpdateTransactionResponse>(
    RequestType.POST,
    apiMap.UPDATE,
    {
      update: { broker, agent, consent },
      transactionId,
    },
  ).then((res) => res.data);
}

type AdditionalTxnResponseData =
  | {
      /** whether user logged in for the first time */
      signup?: boolean;
      /** token with smallcaseAuthId */
      smallcaseAuthToken?: string;
      /** broker to which user connected */
      broker: string;
      /** Updates any intermediate action that was taken during the transaction flow */
      actions?: Record<string, string>;
    }
  | {
      actions?: Record<string, string>;
    };

/**
 * extracts signup, smallcaseAuthToken and broker from transaction api response
 * @param transaction response from transaction status api
 */
export function getAuthDataFromTransaction(
  transaction: Transaction,
  broker?: string,
): AdditionalTxnResponseData | null {
  const brokerString = broker || transaction.meta.broker;
  if (!transaction?.success.smallcaseAuthToken) {
    // Checks if the action object is present and returns even if the smallcaseAuthToken is missing
    return {
      ...(transaction.actions && { actions: transaction.actions }),
      ...(brokerString ? { broker: brokerString } : {}),
    };
  }
  const authData: AdditionalTxnResponseData = {
    smallcaseAuthToken: transaction.success.smallcaseAuthToken,
    broker: transaction.meta.broker || broker,
    ...(transaction.actions && { actions: transaction.actions }),
  };
  if ('signup' in transaction.success)
    authData.signup = transaction.success.signup;
  return authData;
}

type AdditionalErrorInfo = AdditionalTxnResponseData | LeadData | null;
/**
 * wrapper for throwing errors\
 * additionally:
 * - marks transaction with error status if required and
 * - inserts data-hints to error objects
 * - closes all open frames on error
 */
export async function throwError({
  markTransaction,
  errorObj,
  transactionId,
  error,
  data,
}: {
  markTransaction: boolean;
  errorObj?: SdkErrorStruct;
  transactionId?: string;
  error?: GatewayError;
  data?: AdditionalErrorInfo | null;
}) {
  let gwError = markTransaction ? errorObj.error : error;
  if (data) {
    gwError = new GatewayError(gwError.message, gwError.code, data);
  }
  if (markTransaction) {
    await markTransactionStatus({
      transactionId,
      status: transactionStatus.ERRORED,
      errorMessage: errorObj.message,
      errorCode: errorObj.code,
    });
  }
  await WindowManager.closeAll();
  throw gwError;
}

function createUserActionPromise() {
  // Define iframe message handler callback
  const onMessagePromiseObj = listenMessageEvent(
    PostMessageType.LOGIN_BUTTON_CLICKED,
  );
  const onConsentBrokerLoginClick = listenMessageEvent(
    PostMessageType.LOGIN_BUTTON_CLICKED_CONSENT_REQUIRED,
  );
  const onUserCloseListener = listenMessageEvent(
    PostMessageType.USER_CANCELLED,
  );
  const onUserSignupClick = listenMessageEvent(PostMessageType.USER_SIGNUP);
  const onTweetClick = listenMessageEvent(PostMessageType.BROKER_TWEET);

  const userAction = Promise.race([
    onMessagePromiseObj.promise,
    onUserCloseListener.promise,
    onUserSignupClick.promise,
    onTweetClick.promise,
    onConsentBrokerLoginClick.promise,
  ]).then((res) => {
    onMessagePromiseObj.cancel();
    onUserCloseListener.cancel();
    onUserSignupClick.cancel();
    onTweetClick.cancel();
    onConsentBrokerLoginClick.cancel();
    return res;
  });

  return userAction;
}

type BrokerChooserParams = {
  shouldClose: boolean;
  brokersToshow?: string[];
  leprechaun: boolean;
  transactionId: string;
  intent: string;
  orderConfig: OrderConfig | {};
  recentBrokers: string[];
  recentIntents: { [intent: string]: number };
  txnConfig: TransactionConfig | {};
  transaction: Transaction;
};
/**
 * `openBrokerChooser` will open broker chooser iframe and wait till broker is chosen
 */
export async function openBrokerChooser({
  shouldClose,
  brokersToshow = null,
  leprechaun,
  transactionId,
  intent,
  orderConfig,
  recentBrokers,
  recentIntents = {},
  txnConfig,
  transaction,
}: BrokerChooserParams) {
  const connectParams = qs.stringify(
    {
      action: GatewayLogin,
      b: brokersToshow,
      leprechaun,
      distributor: store.displayName,
      gateway: store.gateway,
    },
    { arrayFormat: 'bracket' },
  );

  await to(
    WindowManager.openIndirect({
      secondaryStatus: `connect?${connectParams}`,
      intent,
      intentData: { orderConfig, txnConfig },
      recentBrokers,
      isReturningUser: recentIntents[intent] > 0,
    }),
  );
  // Wait till message received or user close
  let eventMessage = await createUserActionPromise();
  let eventData = eventMessage.data;

  let updateConsent = false;

  // TODO: Remove await from loop
  // check if consent screen is shown
  if (
    eventData.type === PostMessageType.LOGIN_BUTTON_CLICKED_CONSENT_REQUIRED
  ) {
    // wait for user to give consent
    do {
      // trigger update broker call
      to(updateTransaction({ broker: eventData.broker, transactionId }));

      // await for user consent click
      // eslint-disable-next-line no-await-in-loop
      eventMessage = await createUserActionPromise();
      eventData = eventMessage.data;
    } while (
      eventData.type === PostMessageType.LOGIN_BUTTON_CLICKED_CONSENT_REQUIRED
    );

    // if consent given, trigger update consent call
    if (
      eventData.type === PostMessageType.LOGIN_BUTTON_CLICKED &&
      eventData.consentGiven
    ) {
      updateConsent = true;
    }
  }

  if (eventData.type === PostMessageType.USER_SIGNUP) {
    const [, signupRes] = await to(signup());
    await throwError({
      markTransaction: true,
      transactionId,
      errorObj: apiErrorCodeMap[1007],
      data: signupRes,
    });
  } else if (eventData.type === PostMessageType.BROKER_TWEET) {
    await throwError({
      markTransaction: true,
      transactionId,
      errorObj: apiErrorCodeMap[1006],
    });
  } else if (eventData.type === PostMessageType.USER_CANCELLED) {
    await throwError({
      markTransaction: true,
      transactionId,
      errorObj: apiErrorCodeMap[1010],
      data: getAuthDataFromTransaction(transaction),
    });
  } else if (shouldClose) {
    await WindowManager.closeAll();
  }

  const { broker } = eventData;

  return {
    broker,
    updateConsent,
  };
}

export function fetchTransactionStatus<
  Intent extends TransactionIntent | NonTransactionalIntent =
    | TransactionIntent
    | NonTransactionalIntent,
>(transactionId: string) {
  return httpHandler<TransactionStatusResponse<Intent>>(
    RequestType.GET,
    apiMap.TRANSACTION_STATUS,
    {
      transactionId,
    },
  ).then((res) => res.data);
}
export function checkMarketStatus(broker: string) {
  return httpHandler<MarketStatusResponse>(
    RequestType.GET,
    apiMap.MARKET_STATUS,
    undefined,
    {
      'x-sc-broker': broker,
    },
  ).then((res) => res.data);
}

export function pollTransactionStatus(
  transactionId: string,
  polLimit = 3,
): Promise<Transaction> {
  let transaction: Transaction;
  let err: Error;
  let pollCount = polLimit;
  return new Promise((resolve) => {
    const poll = setInterval(async () => {
      if (pollCount) {
        [err, { transaction }] = await to(
          fetchTransactionStatus(transactionId),
        );
        if (err) {
          clearInterval(poll);
          await throwError({
            markTransaction: false,
            error: errorMap.API_ERROR,
          });
        }
        if (transaction.status === transactionStatus.COMPLETED) {
          clearInterval(poll);
          return resolve(transaction);
        }
        if (transaction.status === transactionStatus.PROCESSING) {
          pollCount -= 1;
          return pollCount;
        }
      }
      clearInterval(poll);
      return resolve(transaction);
    }, 2000);
  });
}

export function addTransactionListeners(
  {
    broker,
    transactionId,
  }: {
    broker: string;
    transactionId?: string;
  },
  origin: string | string[] = MIDDLE_FRAME_URL,
) {
  const listenForCompletion = listenMessageEvent(
    PostMessageType.TRANSACTION_COMPLETE,
    origin,
  );
  const listenForUserCancelled = listenMessageEvent(
    PostMessageType.USER_CANCELLED,
    origin,
  );
  const listenForWindowClosed = listenMessageEvent(
    PostMessageType.CLOSED_WINDOW,
    origin,
  );
  const listenForLoginRequired = listenMessageEvent(
    PostMessageType.LOGIN_REQUIRED,
    origin,
  );
  const listenForLoginBtnClicked = listenMessageEvent(
    PostMessageType.LOGIN_BUTTON_CLICKED,
    origin,
  );

  listenForLoginBtnClicked.promise.then(({ data }) => {
    if (data.consentGiven)
      to(updateTransaction({ broker, transactionId, consent: true }));
    listenForLoginBtnClicked.cancel();
  });

  return Promise.race([
    listenForCompletion.promise,
    listenForUserCancelled.promise,
    listenForWindowClosed.promise,
    listenForLoginRequired.promise,
  ]).then((message) => {
    listenForCompletion.cancel();
    listenForUserCancelled.cancel();
    listenForWindowClosed.cancel();
    listenForLoginRequired.cancel();
    listenForLoginBtnClicked.cancel();
    return message;
  });
}

/**
 * checks postmessage data and transaction response for error conditions and initiates throwError
 * @param message postmessage event
 * @param transaction response from transaction status api
 */
export async function handleErrorResponse(
  message: MessageEvent<SdkPostMessageData>,
  transaction?: Transaction,
) {
  if (message.data.error === userMismatch) {
    await WindowManager.closePrimary();
    await showStatus({
      secondaryStatus: 'login-failed?reason=wrong_user',
      timeoutMs: -1,
    });
    await throwError({
      markTransaction: false,
      error: errorMap.USER_MISMATCH,
      data: getAuthDataFromTransaction(transaction),
    });
  } else {
    const markTransaction = transaction?.status !== transactionStatus.ERRORED;
    // get error code from message if not present in transaction
    const errorCode = transaction?.error.code ?? message.data.code;
    const errorMessage = transaction?.error.message ?? message.data.error;
    const errorObj = getErrorObj(errorCode, errorMessage);
    await throwError({
      markTransaction,
      transactionId: transaction?.transactionId,
      // pass errorObj if transaction is to be marked, else just pass error
      ...(markTransaction ? { errorObj } : { error: errorObj.error }),
      data: getAuthDataFromTransaction(transaction),
    });
  }
}

/**
 * Handle errors when user aborts transaction flow\
 * Throws the specific variant of user cancelled error based on
 * transaction status and updates transaction status
 * @param transaction response from transaction status api
 */
export async function handleUserCancelledErrors(
  transaction: Transaction,
  broker?: string,
) {
  const markTransaction = transaction.status !== transactionStatus.ERRORED;
  const authData = getAuthDataFromTransaction(transaction, broker);
  if (transaction.status === transactionStatus.INITIALIZED) {
    await throwError({
      markTransaction,
      transactionId: transaction.transactionId,
      errorObj: apiErrorCodeMap[1011],
      data: authData,
    });
  } else if (transaction.status === transactionStatus.USED) {
    await throwError({
      markTransaction,
      transactionId: transaction.transactionId,
      errorObj: apiErrorCodeMap[1012],
      data: authData,
    });
  }
}
type DataObj = { broker: string; smallcaseAuthToken?: string };
/**
 * Throws market closed error if market status api response indicated market being closed\
 * @param transactionId id of the transaction underway
 * @param broker broker key against which market status should be checked
 * @param orderConfig order config that was used to create the transaction with
 */
export async function handleMarketClosedErrors(
  transactionId: string,
  broker: string,
  orderConfig: OrderConfig | {} = {},
) {
  // @ts-ignore; type may not exist but even then this check is safe
  if (orderConfig.type === 'DUMMY') return;

  const [marketError, marketStatus] = await to(checkMarketStatus(broker));

  if (marketError) return;
  if (marketStatus.marketOpen) return;
  if (store.amo && marketStatus.amoActive) return;

  let data: DataObj = { broker };
  if (store.status === ConnectStatus.CONNECTED) {
    data = {
      smallcaseAuthToken: store.smallcaseAuthToken,
      broker: store.broker,
    };
  }
  await throwError({
    markTransaction: true,
    transactionId,
    errorObj: apiErrorCodeMap[4004],
    data,
  });
}
