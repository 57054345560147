import httpHandler, { RequestType } from '../../services/apiService';
import apiMap from '../../utils/apiMap';
import errorMap from '../../utils/errorMap';
import { SipStatusResponse } from '../../types/apiResponses/sipStatus';
import { isSdkReady } from '../../utils/sdkReadiness';

/**
 * @returns {Promise<Object[]>} sipStatus
 */
const getSipStatus = function getSipStatus({ iscid }: { iscid?: string } = {}) {
  return isSdkReady()
    .then(() => {
      if (!iscid) {
        throw errorMap.INVALID_ISCID;
      }
      return httpHandler<SipStatusResponse>(RequestType.GET, apiMap.SIP, {
        iscid,
      }).catch((err) => {
        if (err.res.errorType === 'InputException') {
          throw errorMap.INVALID_ISCID;
        }
        throw err;
      });
    })
    .then((res) => res.data);
};

export default getSipStatus;
