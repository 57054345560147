/* eslint-disable import/no-cycle */
import brokerMap, { BrokerConfig } from '../constants/brokerMap';
import { store } from './userStore';
import errorMap from './errorMap';
import WindowManager, { ChangeTarget, WindowType } from './windowManager';
import PostMessageType from '../constants/postMessageTypes';
import { featureMap, transactionTypes } from '../constants/transactionStatus';
import { SdkPostMessageData } from '../types/messages';
import NonTransactionalIntent from '../constants/nonTransactionalIntents';
import TransactionIntent from '../constants/transactionIntents';
import { OrderConfig } from '../types/transaction';

const SMALLPLUG_MIN_LOADER_TIME = 1000;

/**
 * Get HTMLElement from elementOrSelector, which can be a selector or an actual HTMLElement.
 *
 * @param {string | HTMLElement} elementOrSelector
 * @returns {HTMLElement}
 */
export function getHtmlElement(
  elementOrSelector: string | HTMLElement,
): HTMLElement {
  if (typeof elementOrSelector === 'string') {
    const element = document.querySelector(elementOrSelector);

    if (!(element instanceof HTMLElement)) {
      throw errorMap.INVALID_ELEMENTORSELECTOR;
    }

    return element;
  }
  const element = elementOrSelector;

  if (!(element instanceof HTMLElement)) {
    throw errorMap.INVALID_ELEMENTORSELECTOR;
  }

  if (element === null) {
    throw errorMap.INVALID_ELEMENTORSELECTOR;
  }
  return element;
}

/**
 * Returns iframe element with default styles & resize event listener
 *
 * @returns {HTMLIFrameElement}
 */
export function getIframeElementWithDefaultStyles(headerHeight: number = 0) {
  const iframeElement = document.createElement('iframe');

  iframeElement.setAttribute(
    'sandbox',
    'allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-downloads allow-top-navigation-by-user-activation',
  );
  /**
   * take up the whole screen
   */
  iframeElement.style.position = 'fixed';
  iframeElement.style.left = '0';
  iframeElement.style.right = '0';
  iframeElement.style.top = '0';
  iframeElement.style.bottom = '0';
  iframeElement.style.borderWidth = '0';
  iframeElement.style.width = '100vw';
  iframeElement.style.height = `${window.innerHeight}px`;
  /**
   * ! is this needed?
   */
  iframeElement.style.minHeight = '360px';
  /**
   * hide the iframe by default
   */
  iframeElement.style.visibility = 'hidden';
  iframeElement.style.display = 'none';

  /**
   * resize the iframe to the window size whenever the window size changes
   */
  window.addEventListener('resize', () => {
    if (parseInt(iframeElement.style.height, 10) !== window.innerHeight) {
      iframeElement.style.height = `${window.innerHeight - headerHeight}px`;
    }
  });
  return iframeElement;
}

/**
 * Returns a customized IFrame element which loads connect.smallcase.com
 *
 * * This DOESN'T append the element to DOM
 *
 * @returns {HTMLIFrameElement}
 */
export function prepareMiddleIFrame(url: string) {
  const iframeElement = getIframeElementWithDefaultStyles();

  iframeElement.setAttribute('src', url);
  iframeElement.style.zIndex = '+2147483647'; // safe highest allowed value
  iframeElement.classList.add('scdk-middle-frame');

  return iframeElement;
}

/**
 * Returns the loader element for showing loading state for smallplug
 * @returns {HTMLElement}
 */
function getSmallplugLoader() {
  const loaderElem = document.createElement('img');
  loaderElem.src = 'https://assets.smallcase.com/gateway/sdkAssets/loader.gif';
  loaderElem.style.height = '40px';
  loaderElem.style.width = '40px';
  return loaderElem;
}

/**
 * Returns header element for smallplug with partner branding
 * @param addCloseSmallplugAction Function which assigns close smallplug action to a btn element
 * @param headerHeight Height of the header element
 * @returns {HTMLDivElement}
 */
function getSmallplugHeader(
  addCloseSmallplugAction: (btnElem: HTMLButtonElement) => void,
  headerHeight: number,
  themeColor: string,
) {
  const headerElem = document.createElement('div');
  const headerStyle = `
    z-index: +2147483646;
    width: 100vw;
    height: ${headerHeight}px;
    background-color: ${themeColor};
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity .5s ease-out;
  `;
  headerElem.setAttribute('style', headerStyle);

  const backBtn = document.createElement('button');
  backBtn.type = 'button';
  const backBtnStyle = `
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 50px;
    height: 24px;
    padding: 0px 10px 0px 6px;
    display: flex;
    align-items: center;
    border: none;
    margin-left: 16px;
  `;
  backBtn.setAttribute('style', backBtnStyle);

  const backArrow = document.createElement('img');
  const backIcon =
    'https://assets.smallcase.com/gateway/sdkAssets/chevron-left.svg';
  backArrow.src = backIcon;
  backArrow.style.fontSize = '13px';

  const partnerLogo = document.createElement('img');
  partnerLogo.src = `https://assets.smallcase.com/images/gateway/partnerLogo/big/${store.gateway}-header.png`;
  partnerLogo.setAttribute(
    'style',
    `
    height: 16px;
    margin-left: 4px;
  `,
  );

  backBtn.appendChild(backArrow);
  backBtn.appendChild(partnerLogo);

  addCloseSmallplugAction(backBtn);

  headerElem.appendChild(backBtn);

  return headerElem;
}

/**
 * Returns a container element containing a customized IFrame element which loads
 * distribution module (smallcase.com)
 * This DOESN'T append the element to DOM
 *
 * @returns {HTMLDivElement}
 */
export function prepareSmallplugIFrame(
  url: string,
  height: string,
  width: string,
  addCloseSmallplugAction: (btnElement: HTMLButtonElement) => void,
  headerHeight: number,
  themeColor: string,
) {
  const containerElem = document.createElement('div');
  /**
   * z-index should be less than connect iframe because -
   * transactional iframe to take precedence over smallplug iframe
   */
  const containerElemStyle = `
    height: ${window.innerHeight}px;
    width: ${width};
    z-index: +2147483646;
    display: flex;
    justify-content: center;
    background-color: rgba(0,0,0,.8);
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
  `;
  containerElem.setAttribute('style', containerElemStyle);

  let smallPlugHeader;
  if (window.innerWidth <= 1183) {
    smallPlugHeader = getSmallplugHeader(
      addCloseSmallplugAction,
      headerHeight,
      themeColor,
    );
    containerElem.appendChild(smallPlugHeader);
  }

  const loaderElement = getSmallplugLoader();
  containerElem.appendChild(loaderElement);

  const iframeElement = getIframeElementWithDefaultStyles(headerHeight);
  iframeElement.setAttribute('src', url);
  const iframeStyle = `
    ${iframeElement.style.cssText}
    height: ${height};
    margin-top: 0;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    visibility: visible;
    transition: margin-top .5s ease-out, opacity 1s ease-out;
  `;
  iframeElement.setAttribute('style', iframeStyle);
  if (window.innerWidth <= 1183 && smallPlugHeader) {
    iframeElement.style.marginTop = '100vh';
  } else {
    iframeElement.style.opacity = '0';
  }

  const showDmFrame = () => {
    loaderElement.style.marginBottom = '400px';
    loaderElement.style.opacity = '0';
    if (window.innerWidth <= 1183 && smallPlugHeader) {
      smallPlugHeader.style.opacity = '1';
      iframeElement.style.marginTop = `${headerHeight}px`;
    } else {
      iframeElement.style.opacity = '1';
    }
  };

  let iframeLoaded = false;
  iframeElement.onload = () => {
    iframeLoaded = true;
  };
  setTimeout(() => {
    if (iframeLoaded) {
      showDmFrame();
    } else {
      iframeElement.onload = () => {
        showDmFrame();
      };
    }
  }, SMALLPLUG_MIN_LOADER_TIME);
  iframeElement.id = 'scdk-smallplug-frame';

  if (window.innerWidth > 1183) {
    containerElem.style.padding = '50px';
  }
  containerElem.appendChild(iframeElement);
  return containerElem;
}

/**
 * Returns a customized close button used to close the smallplug iframe
 * * This DOESN'T append the element to DOM and DOESN'T attach event handlers
 */
export function prepareSmallplugCloseBtn() {
  const smallPlugCloseBtn = document.createElement('button');
  smallPlugCloseBtn.innerHTML = '&times';
  smallPlugCloseBtn.style.fontSize = '20px';
  smallPlugCloseBtn.style.background = 'transparent';
  smallPlugCloseBtn.style.color = '#ffffff';
  smallPlugCloseBtn.style.position = 'fixed';
  smallPlugCloseBtn.style.top = '10px';
  smallPlugCloseBtn.style.right = '10px';
  smallPlugCloseBtn.style.border = 'none';
  smallPlugCloseBtn.style.zIndex = '+2147483646';
  smallPlugCloseBtn.style.cursor = 'pointer';
  return smallPlugCloseBtn;
}

/**
 * if allowedOrigins is array, checks if given origin is present in array
 * else checks if give origin passes equality check with allowedOrigins
 */
const matchOrigin = (allowedOrigins: string | string[], origin: string) => {
  if (Array.isArray(allowedOrigins) && allowedOrigins.includes(origin))
    return true;
  return allowedOrigins === origin;
};

type MessageListenerRef<T extends SdkPostMessageData = SdkPostMessageData> = {
  promise: Promise<MessageEvent<T>>;
  cancel: () => void;
};
/**
 * @returns {{promise: Promise<MessageEvent>, cancel: () => void}}
 */
export function listenMessageEvent(
  messageType: string,
  origin: string | string[] = MIDDLE_FRAME_URL,
): MessageListenerRef {
  const returnObj: MessageListenerRef = {
    promise: undefined,
    cancel: undefined,
  };

  returnObj.promise = new Promise((resolve) => {
    const modifiedResolve = (ev: MessageEvent) => {
      if (!matchOrigin(origin, ev.origin)) {
        return;
      }
      if (
        ev.data.type &&
        ev.data.type.toLowerCase() === messageType.toLowerCase()
      ) {
        returnObj.cancel();
        resolve(ev);
      }
    };

    returnObj.cancel = () => {
      window.removeEventListener('message', modifiedResolve);
    };

    window.addEventListener('message', modifiedResolve);
  });

  return returnObj;
}

export function onMessage(
  messageType: string,
  callback,
  origin = MIDDLE_FRAME_URL,
) {
  const handleMessage = (ev: MessageEvent<SdkPostMessageData>) => {
    if (ev.origin !== origin) {
      return;
    }
    if (ev.data.type === messageType) {
      callback(ev.data);
    }
  };
  window.addEventListener('message', handleMessage);

  return {
    cancel: () => window.removeEventListener('message', handleMessage),
  };
}
type PostMessageConfig = {
  messageType?: string;
  messageParams?: any;
};
export function sendPostMessageToFrame(
  { messageType, messageParams = {} }: PostMessageConfig = {},
  frameSelector = 'iframe.scdk-middle-frame',
) {
  const frameElement = getHtmlElement(frameSelector) as HTMLIFrameElement;

  const messageObj: SdkPostMessageData = {
    type: messageType,
    ...messageParams,
  };

  console.log(
    `scdk - Trying to send PostMessage: ${JSON.stringify(messageObj, null, 2)}`,
  );

  if (frameElement !== null) {
    frameElement.contentWindow.postMessage(messageObj, '*');
  }
}

export function sendPostMessageToSmallplug({
  messageType,
  messageParams = {},
}: PostMessageConfig) {
  const smallplugIframeSelector = '#scdk-smallplug-frame';
  sendPostMessageToFrame(
    {
      messageType,
      messageParams,
    },
    smallplugIframeSelector,
  );
}

const LEPRECHAUN_SUFFIX = '-leprechaun';

export function fetchBrokerConfig(broker: string): BrokerConfig {
  // Broker name is lowercase-d and leprechaun suffix is removed
  const brokerNormalized = broker.toLowerCase().replace(LEPRECHAUN_SUFFIX, '');

  const reqdBrokerConfig = {
    ...brokerMap[brokerNormalized],
  };

  if (broker.toLowerCase().indexOf(LEPRECHAUN_SUFFIX) > -1) {
    // Replace getBrokerURL with getLeprechaunURL, if connected broker is leprechaun
    reqdBrokerConfig.getIntentURL = reqdBrokerConfig.getLeprechaunIntentURL;
  }

  return {
    ...reqdBrokerConfig,
  };
}

export const statusTimeoutSec = 3;
type ShowStatusParams = {
  secondaryStatus?: string;
  timeoutMs?: number;
};

// Set timeout = undefined for a persistent status like error statuses
export async function showStatus({
  secondaryStatus,
  timeoutMs = statusTimeoutSec * 1000,
}: ShowStatusParams = {}): Promise<void> {
  return new Promise((resolve) => {
    WindowManager.openIndirect({
      secondaryStatus,
      changeOnly: ChangeTarget.SECONDARY,
    });

    let timeoutId: ReturnType<typeof setTimeout>;
    let onUserCancellation: MessageListenerRef;

    // Cancel both timeout and event listener
    function resolveAfterCancel() {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }
      if (
        onUserCancellation !== undefined &&
        onUserCancellation.cancel !== undefined
      ) {
        onUserCancellation.cancel();
      }

      WindowManager.closeSecondary();

      resolve();
    }

    // Set timeout,if needed
    if (timeoutMs > 0) {
      timeoutId = setTimeout(resolveAfterCancel, timeoutMs);
    }

    // Set close event listener
    onUserCancellation = listenMessageEvent(PostMessageType.USER_CANCELLED);
    onUserCancellation.promise.then(resolveAfterCancel);
  });
}

export const getRiskLabel = (risk: number) => {
  if (risk < 0.8) {
    return 'Low';
  }

  if (risk <= 1.45) {
    return 'Moderate';
  }

  return 'High';
};

// eslint-disable-next-line no-promise-executor-return
export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const isFirstParty = (gateway: string) =>
  gateway === 'tickertape' ||
  gateway === 'tickertape-dev' ||
  gateway === 'tickertape-stag' ||
  gateway === 'smallcase-website';

export const isSmallcaseApp = (gateway: string) =>
  gateway === 'smallcase-website';

export const to = <T, E = Error>(promise: Promise<T>) =>
  promise
    .then((data): [null, T] => [null, data])
    .catch((err): [E, undefined] => [err, undefined]);

export const getTransactionOpenMode = (
  brokerConfig: BrokerConfig,
  thirdPartyCookieSupported: boolean,
): WindowType => {
  if (!thirdPartyCookieSupported) return WindowType.NEWTAB;
  return brokerConfig.transactionOpenMode;
};

export const filterValidBrokers = (
  brokers: string[],
  allowedBrokers: string[],
) => {
  // returns the brokers that are available in the brokerMap
  const allowedBrokersThatExists = allowedBrokers.filter((b) =>
    Object.keys(brokerMap).includes(b),
  );
  if (!brokers) {
    return allowedBrokersThatExists;
  }
  if (Array.isArray(brokers) && brokers.length === 0) {
    return allowedBrokersThatExists;
  }
  return brokers.filter(
    (broker) =>
      allowedBrokersThatExists.indexOf(broker.split('-leprechaun')[0]) > -1,
  );
};

export const getFeature = (
  intent: NonTransactionalIntent | TransactionIntent,
  orderConfig: OrderConfig | {},
): string => {
  if ('type' in orderConfig && orderConfig.type) {
    if (orderConfig.type !== transactionTypes.SECURITIES) {
      const feature = `${intent}_SMT`;
      return featureMap[feature];
    }
    const feature = `${intent}_SST`;
    return featureMap[feature];
  }
  return featureMap[intent];
};

/**
 * Returns device type where scdk is instantiated
 *
 * @returns {string}
 */
export function getAgent() {
  const { innerWidth } = window;
  return innerWidth > 1183 ? 'web' : 'mweb';
}

export function notifyConnectWithGatewayName(gatewayName: string) {
  sendPostMessageToFrame({
    messageType: PostMessageType.UPDATE_GATEWAY_NAME,
    messageParams: {
      gatewayName,
    },
  });
}
