import errorMap from '../../utils/errorMap';
import { throwError, updateTransaction } from './transactionHelpers';
import { getAgent, to } from '../../utils';
import { fetchTransactionDetails } from './utils/fetchTransactionDetails';
import executeBrokerTransaction from './executeBrokerTransaction';
import type scDK from '../../sdk/main';
import {
  TransactionPlatform,
  getTransactionPlatform,
} from './utils/getTransactionPlatform';
import executeMfTransaction from './executeMfTransaction';
import { isSdkReady } from '../../utils/sdkReadiness';

type TriggerTransactionArgs = {
  brokers?: string[];
  leprechaun?: boolean;
  transactionId?: string;
};

/**
 * `triggerTransaction` method will be used to connect the user corresponding
 * to the given userData to a broker.
 */
async function triggerTransaction(
  this: scDK,
  {
    brokers = [],
    leprechaun = false,
    transactionId,
  }: TriggerTransactionArgs = {},
) {
  // verify transaction id
  if (!transactionId || typeof transactionId !== 'string') {
    await throwError({
      markTransaction: false,
      error: errorMap.INVALID_TRANSACTION,
    });
  }

  await isSdkReady();

  // record user agent on transaction document
  const agent = getAgent();
  to(updateTransaction({ transactionId, agent }));

  const transaction = await fetchTransactionDetails(transactionId);

  const platform = getTransactionPlatform(transaction);
  switch (platform) {
    case TransactionPlatform.GW_FE_CLIENT:
    case TransactionPlatform.MF_PLATFORM:
      return executeMfTransaction(transaction, platform);
    default:
      return executeBrokerTransaction.call(
        this,
        transaction,
        brokers,
        leprechaun,
      );
  }
}

export default triggerTransaction;
